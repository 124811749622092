<template>
  <b-card id="guests-card">
    <template #header>
      <status-hero
        :variant="heroVariant"
        :title="heroTitle"
        :text="heroText"
      />
    </template>

    <div class="mt-4">

      <guests-details v-if="guestsOnlineCheckinCompleted" />
      <guests-form v-else />
    </div>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import StatusHero from '@/views/foravila/bookings/booking/components/checkin/StatusHero.vue'
import GuestsDetails from '@/views/foravila/bookings/booking/components/checkin/GuestsDetails.vue'
import GuestsForm from '@/views/foravila/bookings/booking/components/checkin/GuestsForm.vue'

export default {
  components: {
    BCard,
    StatusHero,
    GuestsDetails,
    GuestsForm,
  },
  computed: {
    guests() {
      return this.$store.getters['booking/guests']
    },
    clientOnlineCheckinCompleted() {
      return this.$store.getters['booking/clientOnlineCheckinCompleted']
    },
    guestsOnlineCheckinCompleted() {
      return this.$store.getters['booking/guestsOnlineCheckinCompleted']
    },
    arrivalOnlineCheckinCompleted() {
      return this.$store.getters['booking/arrivalOnlineCheckinCompleted']
    },
    heroVariant() {
      return this.guestsOnlineCheckinCompleted ? 'success' : 'warning'
    },
    heroTitle() {
      if (this.guestsOnlineCheckinCompleted) return this.$t('pages.booking.checkin.niceCompany')
      if (this.guests.length === 0) return this.$t('pages.booking.checkin.whoIsComming')
      return this.$t('pages.booking.checkin.anyoneElse')
    },
    heroText() {
      if (this.guestsOnlineCheckinCompleted) {
        if (!this.clientOnlineCheckinCompleted) return this.$t('pages.booking.checkin.guestsCheckinCompletedNowClient')
        if (!this.arrivalOnlineCheckinCompleted) return this.$t('pages.booking.checkin.guestsCheckinCompletedNowArrival')
        return this.$t('pages.booking.checkin.guestsCheckinCompleted')
      }
      if (this.guests.length === 0) return this.$t('pages.booking.checkin.registerAllGuests')
      return this.$t('pages.booking.checkin.guestsClickkSave')
    },
  },
}
</script>

<style lang="scss">
#guests-card {
  .card-header {
    padding: 0 !important;
  }
}
</style>
