<template>
  <b-row>
    <b-col>
      <h4 class="mb-4">
        {{ $t("pages.booking.review.yourReview") }}
      </h4>

      <div class="d-sm-flex justify-content-sm-between align-items-center">
        <div>
          <b-card-title v-if="reviewAuthor" class="mb-4">
            {{ reviewAuthor }}
          </b-card-title>
          <b-card-sub-title>
            <div class="d-sm-flex align-items-sm-center">
              <div
                v-if="reviewPostDate"
                class="font-weight-normal mb-1 mb-sm-0"
              >
                {{ reviewPostDate }}
              </div>
            </div>
          </b-card-sub-title>
        </div>
        <b-card-title v-if="reviewAverageRating" class="mt-3 mt-sm-0">
          {{ reviewAverageRating }}
          <span> / {{ maxRating }} </span>
        </b-card-title>
      </div>

      <b-row class="my-4 py-4 border-top border-bottom">
        <b-col cols="12" md="3" lg="2" class="mb-2 mb-md-3">
          {{ $t("Facilities") }}
        </b-col>
        <b-col cols="12" md="3" lg="4" class="mb-2 mb-sm-3">
          <b-progress v-model="reviewRateFacilities" max="5" show-value />
        </b-col>
        <b-col cols="12" md="3" lg="2" class="mb-2 mb-md-3">
          {{ $t("Cleaning") }}
        </b-col>
        <b-col cols="12" md="3" lg="4" class="mb-3">
          <b-progress v-model="reviewRateCleaning" max="5" show-value />
        </b-col>
        <b-col cols="12" md="3" lg="2" class="mb-2 mb-md-3">
          {{ $t("Arrival") }}
        </b-col>
        <b-col cols="12" md="3" lg="4" class="mb-3">
          <b-progress v-model="reviewRateCheckin" max="5" show-value />
        </b-col>
        <b-col cols="12" md="3" lg="2" class="mb-2 mb-md-3">
          {{ $t("Location") }}
        </b-col>
        <b-col cols="12" md="3" lg="4" class="mb-3">
          <b-progress v-model="reviewRateLocation" max="5" show-value />
        </b-col>
        <b-col cols="12" md="3" lg="2" class="mb-2 mb-md-0">
          {{ $t("Communication") }}
        </b-col>
        <b-col cols="12" md="3" lg="4" class="mb-3 mb-md-0">
          <b-progress v-model="reviewRateCommunication" max="5" show-value />
        </b-col>
        <b-col cols="12" md="3" lg="2" class="mb-2 mb-md-0">
          {{ $t("Accuracy") }}
        </b-col>
        <b-col cols="12" md="3" lg="4" class="mb-3 mb-sm-0">
          <b-progress v-model="reviewRateAccuracy" max="5" show-value />
        </b-col>
      </b-row>

      <b-row>
        <b-col v-if="reviewComment" cols="12">
          <h6>
            {{ $t("pages.booking.review.publicReview") }}
          </h6>
          <p class="review-comment bg-light p-3">
            {{ reviewComment }}
          </p>
        </b-col>
        <b-col v-if="reviewPrivateComment" cols="12">
          <h6>
            {{ $t("pages.booking.review.privateReview") }}
          </h6>
          <p class="review-comment bg-light p-3">
            {{ reviewPrivateComment }}
          </p>
        </b-col>
        <b-col v-if="reviewReply" cols="12">
          <h6>
            {{ $t("Reply") }}
          </h6>
          <p class="review-comment bg-light p-3">
            {{ reviewReply }}
          </p>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BProgress,
  BCardTitle,
  BCardSubTitle
} from "bootstrap-vue";
import { formatDateStringToDate } from "@/utils/formatters";

export default {
  components: {
    BProgress,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol
  },
  data() {
    return {
      maxRating: 5
    };
  },
  computed: {
    review() {
      return this.$store.getters["booking/review"];
    },
    reviewAuthor() {
      return this.review?.author || null;
    },
    reviewPostDate() {
      const formatting = { day: "numeric", month: "long", year: "numeric" };
      return formatDateStringToDate(
        this.review?.postedAt,
        this.$i18n.locale,
        formatting
      );
    },
    reviewAverageRating() {
      return this.review?.averageRating || null;
    },
    reviewRateFacilities() {
      return this.review?.rateFacilities || null;
    },
    reviewRateCleaning() {
      return this.review?.rateCleaning || null;
    },
    reviewRateLocation() {
      return this.review?.rateLocation || null;
    },
    reviewRateCheckin() {
      return this.review?.rateCheckin || null;
    },
    reviewRateCommunication() {
      return this.review?.rateCommunication || null;
    },
    reviewRateAccuracy() {
      return this.review?.rateAccuracy || null;
    },
    reviewComment() {
      return this.review?.comment || null;
    },
    reviewPrivateComment() {
      return this.review?.privateComment || null;
    },
    reviewReply() {
      return this.review?.reply || null;
    }
  }
};
</script>

<style lang="scss" scoped>
.review-post-date:first-letter {
  text-transform: capitalize;
}
.review-comment {
  white-space: pre-wrap;
}
</style>
