<template>
  <b-card :title="$t('Booking conditions')">
    <booking-policy-preview
      :policy="policy"
      :locale="$i18n.locale"
    />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import BookingPolicyPreview from '@/views/foravila/bookings/booking/components/summary/BookingPolicyPreview.vue'

export default {
  components: {
    BCard,
    BookingPolicyPreview,
  },
  data() {
    return {

    }
  },
  computed: {
    policy() {
      return this.$store.getters['booking/policy']
    },
    isLastMinute() {
      return this.$store.getters['booking/isLastMinute']
    },
  },

}
</script>

<style lang="scss">
</style>
