<template>
  <b-overlay :show="loading" variant="white" :opacity="0.9">
    <validation-observer ref="guestFormRules">
      <b-form id="guest-form">
        <b-row class="mb-3">
          <b-col cols="12">
            <label for="guest-gender">
              {{ $t("Gender") }}
            </label>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                :name="$t('Gender')"
                rules="required"
              >
                <b-form-radio-group
                  id="guest-gender"
                  v-model="newGuest.gender"
                  :options="genderOptions"
                  text-field="label"
                  class="fr-radio-button w-100"
                  :class="{ 'is-invalid': errors.length > 0 }"
                  buttons
                  button-variant="outline-secondary"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              :label="$t('First name')"
              label-for="guest-first-name"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('First name')"
                rules="required"
              >
                <b-form-input
                  id="guest-first-name"
                  v-model="newGuest.firstName"
                  :state="errors.length > 0 ? false : null"
                  trim
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group :label="$t('Last name')" label-for="guest-last-name">
              <validation-provider
                #default="{ errors }"
                :name="$t('Last name')"
                rules="required"
              >
                <b-form-input
                  id="guest-last-name"
                  v-model="newGuest.lastName"
                  :state="errors.length > 0 ? false : null"
                  trim
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <label for="guest-birthday">
              {{ $t("Birthdate") }}
            </label>
            <validation-provider
              #default="{ errors }"
              name="birthdate"
              rules="required|pastdate"
            >
              <b-datepicker
                v-model="newGuest.birthDate"
                :max="new Date()"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12" class="mt-3">
            <b-form-group :label="$t('Nationality')" label-for="guest-country">
              <validation-provider
                #default="{ errors }"
                :name="$t('Nationality')"
                rules="required"
              >
                <v-select
                  id="guest-country"
                  v-model="newGuest.nationality"
                  :options="countryOptions"
                  :reduce="option => option.value"
                  class="w-full"
                  :class="{ 'is-invalid': errors.length > 0 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <label for="guest-id-type">
              {{ $t("ID type") }}
            </label>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                :name="$t('ID type')"
                rules="required"
              >
                <b-form-radio-group
                  id="guest-id-type"
                  v-model="newGuest.idType"
                  :options="idTypeOptions"
                  text-field="label"
                  buttons
                  button-variant="outline-secondary"
                  class="fr-radio-button w-100"
                  :class="{ 'is-invalid': errors.length > 0 }"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group :label="$t('ID number')" label-for="guest-id-number">
              <validation-provider
                #default="{ errors }"
                :name="$t('ID number')"
                rules="required"
              >
                <b-form-input
                  id="guest-id-number"
                  v-model="newGuest.idNumber"
                  trim
                  :formatter="value => value.toUpperCase()"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <label for="guest-id-expiry">
              {{ $t("ID issue date") }}
            </label>
            <validation-provider
              #default="{ errors }"
              name="idIssueDate"
              rules="required|pastdate"
            >
              <b-datepicker
                v-model="newGuest.idIssueDate"
                :max="new Date()"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- SIGNATURE -->
        <b-row class="mb-4">
          <b-col cols="12" class="mb-1">
            <label for="guest-signature">
              {{ $t("Signature") }}
              <small
                class="cursor-pointer text-primary ml-25"
                @click="clearSignature()"
              >
                {{ $t("pages.booking.deleteSignature") }}
              </small>
            </label>
          </b-col>
          <b-col v-if="guest && newGuest.signature" cols="12">
            <b-img
              id="guest-signature"
              class="guest-signature mb-1"
              :src="newGuest.signature"
              alt=""
              fluid
              center
            />
          </b-col>
          <b-col v-else cols="12">
            <vue-signature-pad
              id="signature-pad"
              ref="signaturePad"
              width="100%"
              height="250px"
              class="mb-1 cursor-pointer"
              :class="{ 'is-invalid': signaturePadError }"
              :options="signaturePadOptions"
            />
          </b-col>
        </b-row>

        <!-- BUTTONS -->
        <b-row
          class="mb-1 d-flex justify-content-between justify-content-sm-end"
        >
          <b-col cols="6" sm="auto">
            <b-button block variant="light" @click="cancel()">
              {{ $t("Cancel") }}
            </b-button>
          </b-col>
          <b-col cols="6" sm="auto">
            <b-button block variant="primary" @click="save()">
              {{ $t("Save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BButton,
  BImg
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  countryOptions,
  genderOptions,
  idTypeOptions
} from "@/utils/select-options";
import VueSignaturePad from "vue-signature-pad";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, pastdate } from "@foravila-validations";
import BDatepicker from "@foravila-core/components/b-datepicker/BDatepicker.vue";
import { formatDateObjectToDatabaseDateTime } from "@/utils/formatters";

export default {
  components: {
    BOverlay,
    vSelect,
    BFormRadioGroup,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BImg,
    VueSignaturePad,
    ValidationProvider,
    ValidationObserver,
    BDatepicker
  },
  props: {
    guest: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      newGuest: null,
      signaturePadOptions: {
        penColor: "#000",
        onBegin: () => {
          if (this.$refs.signaturePad.isEmpty())
            this.$refs.signaturePad.resizeCanvas();
        }
      },
      signaturePadError: null,
      required,
      pastdate
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    genderOptions() {
      return genderOptions(this.$i18n.locale);
    },
    countryOptions() {
      return countryOptions(this.$i18n.locale);
    },
    idTypeOptions() {
      return idTypeOptions(this.$i18n.locale).filter(
        option => option.value !== "CIF"
      );
    },
    isStacked() {
      const currentBreakpoint = this.$store.getters["app/currentBreakPoint"];
      return currentBreakpoint === "xs" || currentBreakpoint === "sm" || false;
    },
    emptyForm() {
      return (
        !this.newGuest.gender &&
        !this.newGuest.firstName &&
        !this.newGuest.lastName &&
        !this.newGuest.birthDate &&
        !this.newGuest.nationality &&
        !this.newGuest.idType &&
        !this.newGuest.idNumber &&
        !this.newGuest.idIssueDate &&
        !this.newGuest.signature
      );
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.reset();
      if (!this.guest) return;
      this.newGuest = {
        gender: this.guest.gender || null,
        firstName: this.guest.firstName || null,
        lastName: this.guest.lastName || null,
        birthDate: this.guest.birthDate || null,
        nationality: this.guest.nationality || null,
        idType: this.guest.idType || null,
        idNumber: this.guest.idNumber || null,
        idIssueDate: this.guest.idIssueDate || null,
        signature: this.guest.signature || null
      };
    },
    reset() {
      this.newGuest = {
        gender: null,
        firstName: null,
        lastName: null,
        birthDate: null,
        nationality: null,
        idType: null,
        idNumber: null,
        idIssueDate: null,
        signature: null
      };
    },
    clearSignature() {
      this.newGuest.signature = null;
      if (this.$refs.signaturePad) this.$refs.signaturePad.clearSignature();
      this.validateSignature();
    },
    cancel() {
      this.$emit("cancel");
    },
    save() {
      const validSignature = this.validateSignature();
      this.$refs.guestFormRules.validate().then(success => {
        if (success && validSignature) {
          if (this.guest?.uuid) this.editGuest();
          else this.createGuest();
        }
      });
    },
    createGuest() {
      try {
        this.setLoading(true);

        if (this.$refs.signaturePad) {
          const { data } = this.$refs.signaturePad.saveSignature();
          this.newGuest.signature = data || null;
        }

        // TODO: improve this time delay. It is set here to give time to the signature pad to process the data
        setTimeout(() => {
          this.$store
            .dispatch("booking/addGuest", {
              booking: this.booking["@id"],
              ...this.newGuest,
              confirmed: true,
              confirmedDate: formatDateObjectToDatabaseDateTime(new Date())
            })
            .then(() => this.$emit("guest-added"))
            .catch(() => {
              this.$emit("add-guest-error");
            })
            .finally(() => {
              this.setLoading(false);
            });
        }, 200);
      } catch (error) {
        this.setLoading(false);
      }
    },
    editGuest() {
      try {
        this.setLoading(true);

        if (this.$refs.signaturePad) {
          const { data } = this.$refs.signaturePad.saveSignature();
          this.newGuest.signature = data || null;
        }

        // TODO: improve this time delay. It is set here to give time to the signature pad to process the data
        setTimeout(() => {
          this.$store
            .dispatch("booking/updateGuest", {
              uuid: this.guest.uuid,
              ...this.newGuest
            })
            .then(() => this.$emit("guest-edited"))
            .catch(() => {
              this.$emit("edit-guest-error");
            })
            .finally(() => {
              this.setLoading(false);
            });
        }, 200);
      } catch (error) {
        this.setLoading(false);
      }
    },
    setLoading(loading) {
      this.loading = loading;
      this.$emit("loading", loading);
    },
    validateSignature() {
      if (!this.$refs.signaturePad) {
        this.signaturePadError = null;
        return true;
      }
      if (this.$refs.signaturePad.isEmpty()) {
        this.signaturePadError = true;
        return false;
      }
      this.signaturePadError = false;
      return true;
    }
  }
};
</script>

<style lang="scss">
#guest-form {
  .guest-signature {
    max-width: 250px;
  }
  #signature-pad {
    border: solid 1px #d8d6de;
    background-origin: border-box;
    background-clip: content-box, border-box;
    &.is-invalid {
      border: solid 1px #ea5455;
    }
  }
}
</style>
