<template>
  <div id="instructions-picture-gallery">
    <b-divider size="xl" />
    <b-row>
      <b-col cols="12" class="mb-4">
        <h5>{{ $t("pages.booking.instructions.helpfulDetails") }}</h5>
      </b-col>

      <b-col cols="12" class="d-none d-sm-block">
        <b-card-group deck>
          <b-row class="w-100">
            <b-col
              v-for="(picture, pictureIndex) in pictures"
              :key="`${name}-grid-picture-${pictureIndex}`"
              cols="6"
              lg="3"
            >
              <b-card
                :img-src="picture.thumbnailUrl"
                img-alt="Image"
                img-top
                class="mb-4 cursor-pointer border-bottom-0"
                no-body
                @click="openPicture(pictureIndex)"
              >
                <b-card-text v-if="getPictureDescription(picture)" class="mt-2">
                  {{ getPictureDescription(picture) }}
                </b-card-text>
              </b-card>
            </b-col>
          </b-row>
        </b-card-group>
      </b-col>

      <b-col cols="12" class="d-sm-none">
        <swiper
          :ref="`${name}Slider`"
          :options="swiperOptions"
          @click-slide="openPicture($event)"
        >
          <swiper-slide
            v-for="(picture, pictureIndex) in pictures"
            :key="`${name}-slider-picture-${pictureIndex}`"
          >
            <b-card
              :img-src="picture.thumbnailUrl"
              img-alt="Picture"
              img-top
              style=" --aspect-ratio: 0.9"
              class="cursor-pointer"
              no-body
            >
              <b-card-text v-if="getPictureDescription(picture)" class="mt-2">
                {{ getPictureDescription(picture) }}
              </b-card-text>
            </b-card>
          </swiper-slide>
        </swiper>
      </b-col>

      <vue-chocolat
        :ref="`${name}Lighbox`"
        :items="lightboxPictures"
        :options="{ loop: true }"
      />
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BCardGroup, BCard, BCardText } from "bootstrap-vue";
import BDivider from "@foravila-core/components/b-divider/BDivider.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import VueChocolat from "vue-chocolat";

export default {
  components: {
    BRow,
    BCol,
    BDivider,
    BCardGroup,
    BCard,
    BCardText,
    Swiper,
    SwiperSlide,
    VueChocolat
  },
  props: {
    pictures: {
      type: Array,
      required: true
    },
    name: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: "auto",
        centeredSlides: false,
        spaceBetween: 32,
        freeMode: false,
        grabCursor: true,
        loop: false,
        slidesOffsetAfter: 0,
        // Responsive breakpoints
        breakpoints: {
          // when window width is >= 576px
          576: {
            slidesOffsetAfter: 15,
            centeredSlides: true
          }
        }
      }
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale.substring(0, 2);
    },
    lightboxPictures() {
      if (!this.pictures.length) return [];
      return this.pictures.map(picture => ({
        src: picture.url,
        title: this.getPictureDescription(picture)
      }));
    }
  },
  methods: {
    openPicture(pictureIndex) {
      const { api } = this.$refs[`${this.name}Lighbox`];
      api.open(pictureIndex);
    },
    getPictureDescription(picture) {
      if (!picture || !picture.descriptions?.length) return null;
      const localizedDescription = picture.descriptions.find(
        description => description.language === this.locale
      );
      return localizedDescription?.content || null;
    }
  }
};
</script>

<style lang="scss">
#instructions-picture-gallery {
  .chocolat-wrapper {
    z-index: 1055 !important;
  }
  .chocolat-overlay.chocolat-visible {
    opacity: 1 !important;
  }
  .chocolat-wrapper .chocolat-right {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 64 64'%3E%3Ccircle id='Ellipse_216' data-name='Ellipse 216' cx='32' cy='32' r='32' fill='%23fff'/%3E%3Cg id='Icon-Arrow-Right' transform='translate(56 56) rotate(180)'%3E%3Cg id='Icon-Arrow-Right-2' data-name='Icon-Arrow-Right' transform='translate(48 48) rotate(180)'%3E%3Crect id='Rectangle_396' data-name='Rectangle 396' width='48' height='48' transform='translate(0)' fill='none'/%3E%3Cpath id='Path_239' data-name='Path 239' d='M15.519,0,13.871,1.648,24.823,12.6H0v2.332H24.823L13.871,25.884l1.648,1.648L29.286,13.766Z' transform='translate(9.363 9.761)' fill='%23ffcd00'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  }
  .chocolat-wrapper .chocolat-left {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 64 64'%3E%3Ccircle id='Ellipse_216' data-name='Ellipse 216' cx='32' cy='32' r='32' transform='translate(64 64) rotate(180)' fill='%23fff'/%3E%3Cg id='Icon-Arrow-Right' transform='translate(8 8)'%3E%3Cg id='Icon-Arrow-Right-2' data-name='Icon-Arrow-Right' transform='translate(48 48) rotate(180)'%3E%3Crect id='Rectangle_396' data-name='Rectangle 396' width='48' height='48' transform='translate(0)' fill='none'/%3E%3Cpath id='Path_239' data-name='Path 239' d='M15.519,0,13.871,1.648,24.823,12.6H0v2.332H24.823L13.871,25.884l1.648,1.648L29.286,13.766Z' transform='translate(9.363 9.761)' fill='%23ffcd00'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  }
  .chocolat-wrapper .chocolat-close {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='64' height='64' viewBox='0 0 64 64'%3E%3Ccircle id='Ellipse_216' data-name='Ellipse 216' cx='32' cy='32' r='32' transform='translate(64 64) rotate(180)' fill='%23fff'/%3E%3Cg id='Icon-Close' transform='translate(8 8)'%3E%3Crect id='Rectangle_457' data-name='Rectangle 457' width='48' height='48' fill='none'/%3E%3Cpath id='Path_237' data-name='Path 237' d='M42.681,18.76l-1.82-1.82L29.81,27.99,18.76,16.94l-1.82,1.82L27.99,29.81,16.94,40.861l1.82,1.82L29.81,31.63l11.05,11.051,1.82-1.82L31.63,29.81Z' transform='translate(-6.133 -5.779)' fill='%23ffcd00'/%3E%3C/g%3E%3C/svg%3E%0A");
  }
  .chocolat-wrapper .chocolat-bottom {
    display: none;
  }
  .swiper-container {
    padding-right: 15px;
  }
  .swiper-wrapper {
    padding-left: 15px;
  }
  .swiper-slide {
    width: 160px !important;
    height: auto !important;
    position: relative;
  }
}
</style>
