<template>
  <b-card id="booking-info-card" :img-src="pictureUrl" img-height="280px">
    <b-row class="mb-2 d-sm-flex justify-content-sm-between">
      <b-col
        v-if="accommodationName"
        order="2"
        order-sm="1"
        cols="12"
        sm="auto"
      >
        <h3>
          {{ accommodationName }}
        </h3>
        <p v-if="accommodationLocation" class="text-primary">
          {{ accommodationLocation }}
        </p>
      </b-col>
      <b-col order="1" order-sm="2" cols="12" sm="auto" class="mb-3 mb-md-0">
        <b-status-badge :status="bookingStatus" />
      </b-col>
    </b-row>

    <b-row class="mb-3">
      <b-col v-if="bookingDateText" cols="12" sm="3" class="mb-2">
        <div class="font-weight-bold">
          {{ $t("Booking date") }}
        </div>
        <div class="font-weight-light">
          {{ bookingDateText }}
        </div>
      </b-col>
      <b-col v-if="localizatorText" cols="12" sm="3" class="mb-2">
        <div class="font-weight-bold">
          {{ $t("Booking nº") }}
        </div>
        <div class="font-weight-light">
          {{ localizatorText }}
        </div>
      </b-col>
      <b-col v-if="sourceText" cols="12" sm="3" class="mb-2">
        <div class="font-weight-bold">
          {{ $t("Agency") }}
        </div>
        <div class="font-weight-light">
          {{ sourceText }}
        </div>
      </b-col>
      <b-col v-if="sourceLocalizatorText" cols="12" sm="3" class="mb-2">
        <div class="font-weight-bold">
          {{ $t("Reference") }}
        </div>
        <div class="font-weight-light">
          {{ sourceLocalizatorText }}
        </div>
      </b-col>
      <b-col v-if="checkinText" cols="12" sm="3" class="mb-2">
        <div class="font-weight-bold">
          {{ $t("Check-in") }}
        </div>
        <div class="font-weight-light">
          {{ checkinText }}
        </div>
      </b-col>
      <b-col v-if="checkoutText" cols="12" sm="3" class="mb-2">
        <div class="font-weight-bold">
          {{ $t("Check out") }}
        </div>
        <div class="font-weight-light">
          {{ checkoutText }}
        </div>
      </b-col>
      <b-col v-if="nightsText" cols="12" sm="3" class="mb-2">
        <div class="font-weight-bold">
          {{ $t("Stay") }}
        </div>
        <div class="font-weight-light">
          {{ nightsText }}
        </div>
      </b-col>
      <b-col v-if="guestsText" cols="12" sm="3" class="mb-2">
        <div class="font-weight-bold">
          {{ $t("Guests") }}
        </div>
        <div class="font-weight-light">
          {{ guestsText }}
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
import { formatDateStringToDate } from "@/utils/formatters";
import { ACCOMMODATION_PLACEHOLDER_XL } from "@/utils/consts";
import { getNightsText, getGuestsText, getOtaName } from "@/utils/methods";
import BStatusBadge from "@foravila-core/components/b-status-badge/BStatusBadge.vue";

export default {
  components: {
    BStatusBadge,
    BCard,
    BRow,
    BCol
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    bookingStatus() {
      return this.booking?.status || null;
    },
    accommodation() {
      return this.$store.getters["booking/accommodation"];
    },
    pictureUrl() {
      return (
        this.accommodation?.coverPicture?.url || ACCOMMODATION_PLACEHOLDER_XL
      );
    },
    accommodationName() {
      return this.accommodation?.name || null;
    },
    accommodationLocation() {
      if (!this.accommodation?.location) return null;
      const location = [];
      if (this.accommodation.location.city)
        location.push(this.accommodation.location.city);
      if (this.accommodation.location.region)
        location.push(this.accommodation.location.region);
      return location.length ? location.join(", ") : null;
    },
    bookingDateText() {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit"
      };
      return (
        formatDateStringToDate(
          this.booking.date,
          this.$i18n.locale,
          formatting
        ) || null
      );
    },
    nightsText() {
      return getNightsText(this.booking.nights, this.$i18n.locale) || null;
    },
    guestsText() {
      return (
        getGuestsText(
          {
            adults: this.booking.adults,
            children: this.booking.children,
            babies: this.booking.babies
          },
          this.$i18n.locale
        ) || null
      );
    },
    localizatorText() {
      return this.booking?.localizator || null;
    },
    sourceText() {
      return getOtaName(this.booking.source) || null;
    },
    sourceLocalizatorText() {
      return this.booking?.sourceLocalizator || null;
    },
    checkinText() {
      const formatting = { day: "numeric", month: "long", year: "numeric" };
      return (
        formatDateStringToDate(
          this.booking.checkin,
          this.$i18n.locale,
          formatting
        ) || null
      );
    },
    checkoutText() {
      const formatting = { day: "numeric", month: "long", year: "numeric" };
      return (
        formatDateStringToDate(
          this.booking.checkout,
          this.$i18n.locale,
          formatting
        ) || null
      );
    }
  }
};
</script>
<style lang="scss">
#booking-info-card > img {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
</style>
