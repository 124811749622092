<template>
  <div>
    <h4>{{ $t("Booking holder") }}</h4>
    <h5 class="mt-4 mb-3">
      {{ $t("Personal information") }}
    </h5>
    <b-row>
      <b-col cols="12" sm="6" lg="3" class="mb-2">
        <div class="font-weight-bold">
          {{ $t("First name") }}
        </div>
        <div class="font-weight-light">
          {{ clientFirstName }}
        </div>
      </b-col>
      <b-col cols="12" sm="6" lg="3" class="mb-2">
        <div class="font-weight-bold">
          {{ $t("Last name") }}
        </div>
        <div class="font-weight-light">
          {{ clientLastName }}
        </div>
      </b-col>
      <b-col cols="12" sm="6" lg="3" class="mb-2">
        <div class="font-weight-bold">
          {{ $t("Phone number") }}
        </div>
        <div class="font-weight-light">
          {{ clientPhone }}
        </div>
      </b-col>
      <b-col cols="12" sm="6" lg="3" class="mb-2">
        <div class="font-weight-bold">
          {{ $t("Email") }}
        </div>
        <div class="font-weight-light">
          {{ clientEmail }}
        </div>
      </b-col>
    </b-row>

    <h5 class="mt-4 mb-3">
      {{ $t("Address") }}
    </h5>
    <b-row>
      <b-col cols="12" md="8" lg="12" class="mb-4">
        <div class="font-weight-light">
          {{ clientAddress }}
        </div>
      </b-col>
    </b-row>

    <h5 class="mt-4 mb-3">
      {{ $t("Identification") }}
    </h5>
    <b-row>
      <b-col cols="12" sm="6" md="4" lg="3" class="mb-2">
        <div class="font-weight-light">
          {{ clientIdType }}: {{ clientIdNumber }}
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="d-flex justify-content-end">
        <b-button v-b-modal.edit-checkin-modal variant="primary">
          {{ $t("Edit") }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol, BButton, VBModal } from "bootstrap-vue";
import { getFullAddress, getIdTypeName } from "@/utils/methods";

export default {
  components: {
    BRow,
    BCol,
    BButton
  },
  directives: {
    "b-modal": VBModal
  },
  computed: {
    client() {
      return this.$store.getters["client/client"];
    },
    clientFirstName() {
      return this.client?.firstName || this.$t("Not defined");
    },
    clientLastName() {
      return this.client?.lastName || this.$t("Not defined");
    },
    clientPhone() {
      return this.client?.phone || this.$t("Not defined");
    },
    clientEmail() {
      return this.client?.email || this.$t("Not defined");
    },
    clientLocation() {
      return this.$store.getters["client/location"];
    },
    clientAddress() {
      return (
        getFullAddress(this.clientLocation, this.$i18n.locale) ||
        this.$t("Not defined")
      );
    },
    clientIdType() {
      return (
        getIdTypeName(this.client?.idType, this.$i18n.locale) ||
        this.$t("Not defined")
      );
    },
    clientIdNumber() {
      return this.client?.idNumber || this.$t("Not defined");
    }
  }
};
</script>
