<template>
  <b-card id="instrucctions-card">
    <template v-if="onlineCheckinCompleted">
      <b-row class="mb-4">
        <b-col cols="12">
          <h3>
            {{ $t("pages.booking.instructions.hi", { name: clientName }) }}
          </h3>
          <p>
            {{
              $t("pages.booking.instructions.thanks", {
                accommodationName: accommodation.name
              })
            }}
          </p>
        </b-col>
      </b-row>
      <app-collapse
        v-if="accommodationLocation"
        class="instructions-accordion"
        accordion
      >
        <app-collapse-item
          :title="$t('pages.booking.instructions.houseLocationTitle')"
          is-visible
          @visible="onLocationTabVisible"
        >
          <b-row>
            <b-col cols="12" sm="8" class="mb-4 mb-sm-0">
              <l-map
                ref="locationMap"
                style="height:300px"
                :zoom="mapZoom"
                :center="mapCenter"
                :options="mapOptions"
                class="d-none d-md-block"
              >
                <l-control-layers position="topright" />
                <l-tile-layer
                  v-for="tileProvider in tileProviders"
                  :key="tileProvider.name"
                  :name="tileProvider.name"
                  :visible="tileProvider.visible"
                  :url="tileProvider.url"
                  layer-type="base"
                />
                <l-marker v-if="mapMarker" :lat-lng="mapMarker" />
              </l-map>
              <b-button
                variant="primary"
                block
                class="mt-2"
                :href="googleMapsLink"
                target="blank"
              >
                <feather-icon icon="MapIcon" class="mr-2" />
                <span>{{
                  $t("pages.booking.instructions.googleMapsButton")
                }}</span>
              </b-button>
            </b-col>
            <b-col cols="12" sm="4">
              <template v-if="accommodationAddress">
                <h6>
                  {{ $t("Address") }}
                </h6>
                <p>{{ accommodationAddress }}</p>
              </template>
              <template v-if="accommodationLocation.latitude">
                <h6>
                  {{ $t("Latitude") }}
                </h6>
                <b-card-text>
                  {{ accommodationLocation.latitude }}
                </b-card-text>
              </template>
              <template v-if="accommodationLocation.longitude">
                <h6>
                  {{ $t("Longitude") }}
                </h6>
                <b-card-text>
                  {{ accommodationLocation.longitude }}
                </b-card-text>
              </template>
            </b-col>
          </b-row>

          <picture-gallery
            v-if="accommodationLocationPictures.length"
            ref="locationPictureGallery"
            name="locationPictureGallery"
            :pictures="accommodationLocationPictures"
          />
        </app-collapse-item>

        <app-collapse-item
          :title="$t('pages.booking.instructions.keysCollectionTitle')"
          @visible="onCheckinTabVisible"
        >
          <b-row v-if="selfCheckin">
            <b-col>
              <feather-icon
                icon="KeyIcon"
                class="text-primary mb-3"
                size="24"
              />
              <h6 class="mb-3 font-weight-bolder">
                {{ $t("pages.booking.instructions.keysSelfCheckin") }}
              </h6>
              <h6 v-if="lockboxCode">
                {{ $t("pages.booking.instructions.keysLockboxCode") }}:
              </h6>
              <p class="font-weight-light">
                {{ lockboxCode }}
              </p>
              <h6 v-if="keysLocation">
                {{ $t("pages.booking.instructions.keysLocation") }}:
              </h6>
              <p class="font-weight-light">
                {{ keysLocation }}
              </p>
              <h6 v-if="checkinComment">
                {{ $t("pages.booking.instructions.keysCheckinComment") }}:
              </h6>
              <p class="font-weight-light">
                {{ checkinComment }}
              </p>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <feather-icon
                icon="UsersIcon"
                class="text-primary mb-3"
                size="24"
              />
              <h6 class="mb-4 font-weight-bolder">
                {{ $t("pages.booking.instructions.keysOwnerCheckin") }}
              </h6>
              <h6>{{ $t("Remember") }}</h6>
              <p class="font-weight-light">
                {{
                  $t("pages.booking.instructions.keysOwnerCheckinInstructions")
                }}
              </p>
            </b-col>
          </b-row>

          <picture-gallery
            v-if="accommodationCheckinPictures.length"
            ref="checkinPictureGallery"
            name="checkinPictureGallery"
            :pictures="accommodationCheckinPictures"
          />
        </app-collapse-item>

        <app-collapse-item
          :title="$t('pages.booking.instructions.keysReturnTitle')"
          @visible="onCheckoutTabVisible"
        >
          <b-row v-if="selfCheckout">
            <b-col>
              <feather-icon
                icon="KeyIcon"
                class="text-primary mb-3"
                size="24"
              />
              <h6 class="mb-3 font-weight-bolder">
                {{ $t("pages.booking.instructions.keysSelfCheckout") }}
              </h6>
              <h6 v-if="lockboxCode">
                {{ $t("pages.booking.instructions.keysLockboxCode") }}:
              </h6>
              <p class="font-weight-light">
                {{ lockboxCode }}
              </p>
              <h6 v-if="keysLocation">
                {{ $t("pages.booking.instructions.keysLocation") }}:
              </h6>
              <p class="font-weight-light">
                {{ keysLocation }}
              </p>
              <h6 v-if="checkoutComment">
                {{ $t("pages.booking.instructions.keysCheckoutComment") }}:
              </h6>
              <p class="font-weight-light">
                {{ checkoutComment }}
              </p>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <feather-icon
                icon="UsersIcon"
                class="text-primary mb-3"
                size="24"
              />
              <h6 class="mb-4 font-weight-bolder">
                {{ $t("pages.booking.instructions.keysOwnerCheckout") }}
              </h6>
              <h6>{{ $t("Remember") }}</h6>
              <p class="font-weight-light">
                {{
                  $t("pages.booking.instructions.keysOwnerCheckoutInstructions")
                }}
              </p>
            </b-col>
          </b-row>

          <picture-gallery
            v-if="accommodationCheckoutPictures.length"
            ref="checkoutPictureGallery"
            name="checkoutPictureGallery"
            :pictures="accommodationCheckoutPictures"
          />
        </app-collapse-item>

        <app-collapse-item
          :title="$t('pages.booking.instructions.contactTitle')"
        >
          <b-row>
            <b-col cols="12" class="mb-3">
              <h6 class="text-center text-sm-left">
                {{ $t("pages.booking.instructions.contactNonUrgent") }}
                <span class="d-none d-sm-inline font-weight-light small">
                  · {{ $t("pages.booking.instructions.from8To14") }}
                </span>
              </h6>
              <div
                class="d-sm-none font-weight-light small text-center text-sm-left"
              >
                {{ $t("pages.booking.instructions.from8To14") }}
              </div>
            </b-col>
            <b-col cols="12" sm="6" lg="4">
              <div class="d-flex flex-column flex-sm-row align-items-center">
                <div class="text-center">
                  <feather-icon
                    icon="MailIcon"
                    size="32"
                    class="mr-0 mr-sm-3"
                  />
                </div>
                <div class="text-center text-sm-left">
                  <div>{{ $t("pages.booking.instructions.byEmail") }}</div>
                  <a
                    target="blank"
                    href="mailto:hola@foravilarentals.com"
                    class="text-primary"
                  >
                    hola@foravilarentals.com
                  </a>
                </div>
              </div>
            </b-col>
            <b-col class="mt-3 mt-sm-0" cols="12" sm="6" lg="4">
              <div class="d-flex flex-column flex-sm-row align-items-center">
                <div class="text-center">
                  <feather-icon
                    icon="SmartphoneIcon"
                    size="32"
                    class="mr-0 mr-sm-3"
                  />
                </div>
                <div class="text-center text-sm-left">
                  <div>{{ $t("pages.booking.instructions.byPhone") }}</div>
                  <a
                    target="blank"
                    href="tel:+34971541247"
                    class="text-primary"
                  >
                    +34 971 54 12 47
                  </a>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-divider size="xl" />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" class="mb-3">
              <h6 class="text-center text-sm-left">
                {{ $t("pages.booking.instructions.contactUrgent") }}
                <span class="d-none d-sm-inline font-weight-light small">
                  · {{ $t("pages.booking.instructions.24Hours") }}
                </span>
              </h6>
              <div
                class="d-sm-none font-weight-light small text-center text-sm-left"
              >
                {{ $t("pages.booking.instructions.24Hours") }}
              </div>
            </b-col>
            <b-col cols="12" sm="6" lg="4">
              <div class="d-flex flex-column flex-sm-row align-items-center">
                <div class="text-center">
                  <feather-icon
                    icon="RotateCcwIcon"
                    size="32"
                    class="mr-0 mr-sm-3"
                  />
                </div>
                <div class="text-center text-sm-left">
                  <div>{{ $t("pages.booking.instructions.byPhone") }}</div>
                  <a
                    class="text-primary"
                    target="blank"
                    href="tel:+34694420702"
                  >
                    +34 694 42 07 02
                  </a>
                </div>
              </div>
            </b-col>
            <b-col cols="12" sm="6" lg="4" class="mt-3 mt-sm-0">
              <div class="d-flex flex-column flex-sm-row align-items-center">
                <div class="text-center">
                  <feather-icon
                    icon="AlertTriangleIcon"
                    size="32"
                    class="mr-0 mr-sm-3"
                  />
                </div>
                <div class="text-center text-sm-left">
                  <div>
                    {{ $t("pages.booking.instructions.emergencyNumber") }}
                  </div>
                  <a target="blank" href="tel:112" class="text-primary">
                    112
                  </a>
                </div>
              </div>
            </b-col>
          </b-row>
        </app-collapse-item>

        <app-collapse-item
          v-for="(faq, faqIndex) in accommodationFaqs"
          :key="faq.uuid"
          :title="faq.title"
          @visible="onFaqTabVisible($event, faqIndex)"
        >
          <b-row>
            <b-col cols="12">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div v-html="faq.content" />
            </b-col>
          </b-row>

          <picture-gallery
            v-if="faq.pictures.length"
            :ref="`faq${faqIndex}PictureGallery`"
            :name="`faq${faqIndex}PictureGallery`"
            :pictures="faq.pictures"
          />
        </app-collapse-item>
      </app-collapse>
    </template>
    <b-row v-else class="text-center py-md-5 py-0">
      <b-col cols="12">
        <b-img
          fluid
          class="missing-checkin-illustration"
          :src="src"
          alt="Illustration"
        />
      </b-col>
      <b-col cols="12">
        <h2 class="mt-5">
          {{ $t("pages.booking.instructions.completeOnlineCheckinFirstTitle") }}
        </h2>
        <h5 class="mt-3">
          {{
            $t("pages.booking.instructions.completeOnlineCheckinFirstSubtitle")
          }}
        </h5>
        <b-button
          variant="primary"
          class="mt-3 mt-md-4"
          @click="onButtonClicked"
        >
          {{ $t("Online check-in") }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton, BCardText, BImg } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import { LMap, LTileLayer, LMarker, LControlLayers } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import { Icon } from "leaflet";
import BDivider from "@foravila-core/components/b-divider/BDivider.vue";
import "swiper/css/swiper.css";
import { getFullAddress } from "@/utils/methods";
import PictureGallery from "@/views/foravila/bookings/booking/components/instructions/PictureGallery.vue";

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  // eslint-disable-next-line global-require
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  // eslint-disable-next-line global-require
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  // eslint-disable-next-line global-require
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

export default {
  name: "InstructionsCard",
  components: {
    BButton,
    BCard,
    BRow,
    BImg,
    BCol,
    LMap,
    LTileLayer,
    LMarker,
    LControlLayers,
    BCardText,
    AppCollapse,
    AppCollapseItem,
    BDivider,
    PictureGallery
  },
  props: {
    src: {
      type: String,
      // eslint-disable-next-line global-require
      default: require("@/assets/foravila/images/illustrations/missing-online-checkin.svg")
    }
  },
  data() {
    return {
      mapZoom: 8,
      mapCenter: [39.772948, 3.015246],
      mapMarker: null,
      mapOptions: { scrollWheelZoom: false }
    };
  },
  computed: {
    locale() {
      return this.$i18n.locale.substring(0, 2);
    },
    accommodation() {
      return this.$store.getters["booking/accommodation"];
    },
    accommodationLocation() {
      return this.accommodation?.location || null;
    },
    accommodationDetails() {
      return this.accommodation?.details || null;
    },
    accommodationLocationPictures() {
      if (!this.accommodation?.details?.pictures?.length) return [];
      const locationPictures = this.accommodation?.details?.pictures?.filter(
        picture => picture.type === "LOCATION"
      );
      return locationPictures.length
        ? locationPictures.map(picture => ({ ...picture.picture }))
        : [];
    },
    accommodationCheckinPictures() {
      if (!this.accommodation?.details?.pictures?.length) return [];
      const locationPictures = this.accommodation?.details?.pictures?.filter(
        picture => picture.type === "CHECKIN"
      );
      return locationPictures.length
        ? locationPictures.map(picture => ({ ...picture.picture }))
        : [];
    },
    accommodationCheckoutPictures() {
      if (!this.accommodation?.details?.pictures?.length) return [];
      const locationPictures = this.accommodation?.details?.pictures?.filter(
        picture => picture.type === "CHECKOUT"
      );
      return locationPictures.length
        ? locationPictures.map(picture => ({ ...picture.picture }))
        : [];
    },
    accommodationFaqs() {
      const faqs = this.accommodation?.faqs || [];
      if (!faqs.length) return [];
      return faqs.filter(
        faq =>
          ["CLIENT", "ALL"].includes(faq.scope) &&
          this.$i18n.locale.includes(faq.language)
      );
    },
    accommodationInstructions() {
      const instructions = this.accommodation?.instructions || [];
      if (!instructions.length) return [];
      return (
        instructions.find(instruction =>
          this.$i18n.locale.includes(instruction.language)
        ) || null
      );
    },
    lockboxCode() {
      return this.accommodationDetails?.lockboxCode || null;
    },
    keysLocation() {
      return this.accommodationInstructions?.keysLocation || null;
    },
    checkinComment() {
      return this.accommodationInstructions?.checkinComment || null;
    },
    checkoutComment() {
      return this.accommodationInstructions?.checkoutComment || null;
    },
    client() {
      return this.$store.getters["client/client"];
    },
    clientName() {
      if (!this.client) return null;
      return this.client.firstName || null;
    },
    onlineCheckinCompleted() {
      return this.$store.getters["booking/onlineCheckinCompleted"];
    },
    googleMapsLink() {
      const lat = this.accommodationLocation?.latitude || "39.772948";
      const lng = this.accommodationLocation?.longitude || "3.015246";
      return `http://www.google.com/maps/place/${lat},${lng}`;
    },
    accommodationAddress() {
      if (!this.accommodationLocation) return null;
      return getFullAddress(
        this.accommodationLocation,
        this.$i18n.locale,
        true,
        true,
        false
      );
    },
    selfCheckin() {
      if (!this.accommodationDetails) return false;
      return this.accommodationDetails?.selfCheckin || false;
    },
    selfCheckout() {
      if (!this.accommodationDetails) return false;
      return this.accommodationDetails?.selfCheckout || false;
    },
    tileProviders() {
      return [
        {
          name: this.$t("Map"),
          visible: true,
          url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        },
        {
          name: this.$t("Terrain"),
          visible: false,
          url:
            "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
        }
      ];
    }
  },
  watch: {
    accommodationLocation() {
      this.initMap();
    }
  },
  created() {
    this.initMap();
  },
  methods: {
    onButtonClicked() {
      this.$store.dispatch("app/setBookingTab", 1);
    },
    initMap() {
      if (
        !this.accommodationLocation?.latitude ||
        !this.accommodationLocation?.longitude
      )
        return;
      this.mapZoom = 15;
      this.mapCenter = [
        this.accommodationLocation.latitude,
        this.accommodationLocation.longitude
      ];
      this.mapMarker = [
        this.accommodationLocation.latitude,
        this.accommodationLocation.longitude
      ];
    },
    onLocationTabVisible(visible) {
      if (!visible) return;
      // Init map
      setTimeout(() => {
        this.$refs.locationMap.mapObject.invalidateSize();
        // FIX CUTRE: hem afegit 100ms d'espera per tal que l'accordion tengui temps a mostrar el mapa
      }, 100);

      // Init mobile slider
      const slider = this.$refs.locationPictureGallery;
      if (!slider) return;
      this.updateSwiperSlider(slider.$refs.locationPictureGallerySlider);
    },
    onCheckinTabVisible(visible) {
      if (!visible) return;
      const slider = this.$refs.checkinPictureGallery;
      if (!slider) return;
      this.updateSwiperSlider(slider.$refs.checkinPictureGallerySlider);
    },
    onCheckoutTabVisible(visible) {
      if (!visible) return;
      const slider = this.$refs.checkoutPictureGallery;
      if (!slider) return;
      this.updateSwiperSlider(slider.$refs.checkoutPictureGallerySlider);
    },
    onFaqTabVisible(visible, faqIndex) {
      if (!visible) return;
      const pictureGalley = this.$refs[`faq${faqIndex}PictureGallery`];
      if (!pictureGalley) return;
      const slider = pictureGalley[0];
      if (!slider) return;
      this.updateSwiperSlider(
        slider.$refs[`faq${faqIndex}PictureGallerySlider`]
      );
    },
    updateSwiperSlider(slider) {
      if (!slider || !slider.$swiper) return;
      setTimeout(() => {
        slider.$swiper.update();
        // FIX CUTRE: hem afegit 100ms d'espera per tal que l'accordion tengui temps a mostrar les imatges
      }, 100);
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/base/colors.scss";
#instrucctions-card {
  .instructions-accordion {
    .card-header {
      background: $light;
      border-radius: 0;
    }
    .card-body {
      padding-top: $spacer * 1.5 !important;
    }
  }

  .missing-checkin-illustration {
    max-width: 15rem;
    @include media-breakpoint-up(md) {
      max-width: 25rem;
    }
  }
}
</style>
