<template>
  <b-card
    :title="content.title"
    :img-src="content.img"
    img-alt="Image"
    img-top
    class="available-service-card cursor-pointer"
    @click="onServiceSelected"
  >
    {{ content.description }}
    <template #footer>
      <div class="d-flex justify-content-center">
        <b-button class="mb-3" variant="primary">
          {{ $t("Book now") }}
        </b-button>
      </div>
    </template>
  </b-card>
</template>

<script>
import { BCard, BButton } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BButton
  },
  props: {
    accommodationService: {
      type: Object,
      required: true
    }
  },
  computed: {
    serviceType() {
      return this.accommodationService.service?.code || null;
    },
    content() {
      // TODO: keep adding services as we create the new pages
      switch (this.serviceType) {
        case "AIRPORT_TRANSFER":
          return {
            title: this.$t("pages.booking.services.airportTransfer.title"),
            description: this.$t(
              "pages.booking.services.airportTransfer.description"
            ),
            // eslint-disable-next-line global-require
            img: require("@/assets/foravila/images/services/transfer-service.jpg")
          };
        case "CHARTER":
          return {
            title: this.$t("pages.booking.services.charter.title"),
            description: this.$t("pages.booking.services.charter.description"),
            // eslint-disable-next-line global-require
            img: require("@/assets/foravila/images/services/charter-service.jpg")
          };
        case "CAR_RENTAL":
          return {
            title: this.$t("pages.booking.services.carRental.title"),
            description: this.$t(
              "pages.booking.services.carRental.description"
            ),
            // eslint-disable-next-line global-require
            img: require("@/assets/foravila/images/services/rental-car-service.png")
          };
        case "CLEANING":
          return {
            title: this.$t("pages.booking.services.cleaning.title"),
            description: this.$t("pages.booking.services.cleaning.description"),
            // eslint-disable-next-line global-require
            img: require("@/assets/foravila/images/services/cleaning-service.jpg")
          };
        default:
          return {
            title: null,
            // eslint-disable-next-line global-require
            img: require("@/assets/foravila/images/services/general-service.jpg")
          };
      }
    }
  },
  methods: {
    onServiceSelected() {
      // TODO: keep adding services as we create the new pages
      switch (this.serviceType) {
        case "AIRPORT_TRANSFER":
          this.$router.push({
            name: "foravila-services-airport-transfer"
          });
          break;
        case "CAR_RENTAL":
          window.open(
            "https://www.autoslagoon.com/?partner=foravilarentals-guests",
            "_blank"
          );
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/include";
.available-service-card {
  max-width: 100% !important;
  min-width: 100% !important;
  @include media-breakpoint-up(sm) {
    max-width: calc(50% - 30px) !important;
    min-width: calc(50% - 30px) !important;
  }
  @include media-breakpoint-up(md) {
    max-width: calc(33.33% - 30px) !important;
    min-width: calc(33.33% - 30px) !important;
  }
  img {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    max-height: 180px;
    object-fit: cover;
  }
  .card-footer {
    padding: 0 !important;
    border: 0 !important;
  }
  // &:hover {
  //   -webkit-transform: translateY(-2px);
  //   transform: translateY(-2px);
  //   -webkit-box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
  //   box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%)
  // }
  // &:focus {
  //   outline: none !important;
  // }
}
</style>
