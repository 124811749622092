var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading,"variant":"white","opacity":0.9}},[_c('validation-observer',{ref:"clientFormRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('h4',[_vm._v(_vm._s(_vm.$t("Booking holder")))]),_c('b-form',{attrs:{"id":"client-form"}},[_c('h5',{staticClass:"my-4"},[_vm._v(" "+_vm._s(_vm.$t("Personal information"))+" ")]),_c('b-row',[_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12","sm":"6","md":"5","xl":"3"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("First name"))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('First name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"w-full mb-2",attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12","sm":"6","md":"7","xl":"3"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("Last name"))+" ")]),_c('b-form-input',{staticClass:"w-full mb-2",model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1),_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12","md":"5","xl":"6"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("Phone number"))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('Phone number'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-phone-number-input',{staticClass:"phone-number-input mb-2",attrs:{"no-flags":"","clearable":"","default-country-code":_vm.phoneCountry,"show-code-on-list":"","translations":_vm.phoneNumberInputTranslations,"color":"#d8d6de","error-color":"#ea5455","valid-color":"#d8d6de","error":!_vm.validPhone},on:{"update":_vm.onPhoneUpdated},scopedSlots:_vm._u([{key:"arrow",fn:function(){return [_c('div',[_c('svg',{staticClass:"feather feather-chevron-down open-indicator vs__open-indicator",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"15px","height":"15px","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round","role":"presentation"}},[_c('polyline',{attrs:{"points":"6 9 12 15 18 9"}})])])]},proxy:true}],null,true),model:{value:(_vm.inputPhone),callback:function ($$v) {_vm.inputPhone=$$v},expression:"inputPhone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12","md":"7","xl":"3"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("Email"))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"w-full mb-2",attrs:{"type":"email","state":errors.length > 0 ? false : null},model:{value:(_vm.clientEmail),callback:function ($$v) {_vm.clientEmail=$$v},expression:"clientEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12","md":"5","xl":"3"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("Preferred language"))+" ")]),_c('v-select',{staticClass:"w-full mb-2",attrs:{"options":_vm.languageOptions,"reduce":function (option) { return option.value; }},model:{value:(_vm.language),callback:function ($$v) {_vm.language=$$v},expression:"language"}})],1)],1),_c('h5',{staticClass:"my-4"},[_vm._v(" "+_vm._s(_vm.$t("Address"))+" ")]),_c('b-row',[_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("Country"))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('Country'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-full mb-2",class:{ 'is-invalid': errors.length > 0 },attrs:{"options":_vm.countryOptions,"reduce":function (option) { return option.value; }},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("Region"))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('Region'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"w-full mb-2",attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.region),callback:function ($$v) {_vm.region=$$v},expression:"region"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("City"))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('City'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"w-full mb-2",attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12","sm":"6","md":"4","xl":"3"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("Postal code"))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('Postal code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"w-full mb-2",attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.zip),callback:function ($$v) {_vm.zip=$$v},expression:"zip"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12","md":"8","xl":"12"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("Address"))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('Address'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"w-full mb-2",attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('h5',{staticClass:"my-4"},[_vm._v(" "+_vm._s(_vm.$t("Identification"))+" ")]),_c('b-row',[_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"font-weight-bold mb-2",attrs:{"for":"client-id-type"}},[_vm._v(" "+_vm._s(_vm.$t("ID type"))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('ID type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',[_c('b-form-radio-group',{staticClass:"fr-radio-button w-100",attrs:{"id":"client-id-type","options":_vm.idTypeOptions,"text-field":"label","buttons":"","button-variant":"outline-secondary"},model:{value:(_vm.idType),callback:function ($$v) {_vm.idType=$$v},expression:"idType"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12","md":"4","xl":"3"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("ID number"))+" ")]),_c('validation-provider',{attrs:{"name":_vm.$t('ID number'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"w-full mb-2",attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.idNumber),callback:function ($$v) {_vm.idNumber=$$v},expression:"idNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":invalid},on:{"click":function($event){$event.preventDefault();return _vm.save($event)}}},[_vm._v(" "+_vm._s(_vm.$t("Save"))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }