<template>
  <div>
    <h4>{{ $t('Guests') }}</h4>
    <guests-list
      :guests="guests"
      read-only
    />

    <b-row class="mt-4">
      <b-col
        cols="12"
        sm="6"
        class="mb-4 mb-sm-0"
      >
        <div class="d-flex align-items-center justify-content-between">
          <label for="children-spinbutton">
            {{ $t('Children') }}
            <div class="text-muted">{{ $t('pages.booking.childrenAgeDefinition') }}</div>
          </label>
        </div>
        <h5>{{ booking.children }}</h5>
      </b-col>

      <b-col
        cols="12"
        sm="6"
      >
        <div class="d-flex align-items-center justify-content-between">
          <label for="babies-spinbutton">
            {{ $t('Babies') }}
            <div class="text-muted">{{ $t('pages.booking.babiesAgeDefinition') }}</div>
          </label>
        </div>
        <div>
          {{ booking.babies }}
          <span v-if="booking.babies > 0">
            {{ requestsText }}
          </span>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-4">
      <b-col class="d-flex justify-content-end">
        <b-button
          v-b-modal.edit-checkin-modal
          variant="primary"
        >
          {{ $t('Edit') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, VBModal,
} from 'bootstrap-vue'
import GuestsList from '@/views/foravila/bookings/booking/components/checkin/GuestsList.vue'

export default {
  name: 'GuestsCard',
  components: {
    BButton,
    BRow,
    BCol,
    GuestsList,
  },
  directives: {
    'b-modal': VBModal,
  },
  computed: {
    booking() {
      return this.$store.getters['booking/booking']
    },
    details() {
      return this.$store.getters['booking/details']
    },
    guests() {
      return this.$store.getters['booking/guests']
    },
    babyCotsText() {
      const babyCotTranslation = this.$t('Baby cot').toLowerCase()
      const babyCotsTranslation = this.$t('Baby cots').toLowerCase()
      if (this.details.babyCots !== 1) return `${this.details.babyCots} ${babyCotsTranslation}`
      return `1 ${babyCotTranslation}`
    },
    babyChairsText() {
      const babyChairTranslation = this.$t('Baby chair').toLowerCase()
      const babyChairsTranslation = this.$t('Baby chairs').toLowerCase()
      if (this.details.babyChairs !== 1) return `${this.details.babyChairs} ${babyChairsTranslation}`
      return `1 ${babyChairTranslation}`
    },
    requestsText() {
      return `(${this.babyCotsText}, ${this.babyChairsText})`
    },
  },
}
</script>
