<template>
  <b-card>
    <review-summary v-if="posted" />
    <review-form v-else />
  </b-card>
</template>
<script>
import { BCard } from 'bootstrap-vue'
import ReviewSummary from '@/views/foravila/bookings/booking/components/review/ReviewSummary.vue'
import ReviewForm from '@/views/foravila/bookings/booking/components/review/ReviewForm.vue'

export default {
  components: {
    BCard,
    ReviewSummary,
    ReviewForm,
  },
  computed: {
    review() {
      return this.$store.getters['booking/review']
    },
    posted() {
      return this.review?.posted || false
    },
  },
}
</script>
