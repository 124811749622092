import countries from "@/utils/countries";

/** **************** */
/** **************** */
/* PRIVATE FUNCTIONS */
/** **************** */
/** **************** */

function simplifyLocale(locale) {
  if (!locale) return null;
  return locale.split("-")[0].toLowerCase();
}

export const countryOptions = (locale = "ca-ES") => {
  switch (simplifyLocale(locale)) {
    case "de":
      return countries.map(c => ({
        label: c.name_de,
        value: c.code,
        countryCode: c.code,
        dialCode: c.dial_code
      }));
    case "en":
      return countries.map(c => ({
        label: c.name_en,
        value: c.code,
        countryCode: c.code,
        dialCode: c.dial_code
      }));
    case "es":
      return countries.map(c => ({
        label: c.name_es,
        value: c.code,
        countryCode: c.code,
        dialCode: c.dial_code
      }));
    case "ca":
      return countries.map(c => ({
        label: c.name_ca,
        value: c.code,
        countryCode: c.code,
        dialCode: c.dial_code
      }));
    default:
      return countries.map(c => ({
        label: c.name_en,
        value: c.code,
        countryCode: c.code,
        dialCode: c.dial_code
      }));
  }
};

export const languageOptions = [
  { label: "Català", value: "ca" },
  { label: "Deutch", value: "de" },
  { label: "Español", value: "es" },
  { label: "English", value: "en" },
  { label: "Français", value: "fr" },
  { label: "Italiano", value: "it" },
  { label: "Pусский", value: "ru" },
  { label: "Altre", value: "other" }
];

export const genderOptions = (locale = "ca-ES") => {
  switch (simplifyLocale(locale)) {
    case "de":
      return [
        { label: "Mann", value: "MALE" },
        { label: "Frau", value: "FEMALE" },
        { label: "Andere", value: "OTHER" }
      ];
    case "en":
      return [
        { label: "Man", value: "MALE" },
        { label: "Woman", value: "FEMALE" },
        { label: "Other", value: "OTHER" }
      ];
    case "es":
      return [
        { label: "Hombre", value: "MALE" },
        { label: "Mujer", value: "FEMALE" },
        { label: "Otro", value: "OTHER" }
      ];
    case "ca":
      return [
        { label: "Home", value: "MALE" },
        { label: "Done", value: "FEMALE" },
        { label: "Altre", value: "OTHER" }
      ];
    default:
      return [
        { label: "Man", value: "MALE" },
        { label: "Woman", value: "FEMALE" },
        { label: "Other", value: "OTHER" }
      ];
  }
};

export const idTypeOptions = (locale = "ca-ES") => {
  switch (simplifyLocale(locale)) {
    case "de":
      return [
        { label: "Reisepass", value: "PASSPORT" },
        { label: "Personalausweis", value: "DNI" },
        { label: "Umsatzsteuer-Identifikationsnummer", value: "CIF" }
      ];
    case "en":
      return [
        { label: "Passport", value: "PASSPORT" },
        { label: "National ID", value: "DNI" },
        { label: "VAT number", value: "CIF" }
      ];
    case "es":
      return [
        { label: "Pasaporte", value: "PASSPORT" },
        { label: "DNI", value: "DNI" },
        { label: "CIF", value: "CIF" }
      ];
    case "ca":
      return [
        { label: "Passaport", value: "PASSPORT" },
        { label: "DNI", value: "DNI" },
        { label: "CIF", value: "CIF" }
      ];
    default:
      return [
        { label: "Passport", value: "PASSPORT" },
        { label: "National ID", value: "DNI" },
        { label: "VAT number", value: "CIF" }
      ];
  }
};

export const transportMethodOptions = (locale = "ca-ES") => {
  switch (simplifyLocale(locale)) {
    case "de":
      return [
        { label: "Flugzeug", value: "PLANE" },
        { label: "Fähre", value: "BOAT" },
        { label: "Kein Transport", value: "NONE" }
      ];
    case "en":
      return [
        { label: "Plane", value: "PLANE" },
        { label: "Ferry", value: "BOAT" },
        { label: "No transport", value: "NONE" }
      ];
    case "es":
      return [
        { label: "Avión", value: "PLANE" },
        { label: "Barco", value: "BOAT" },
        { label: "Sin transporte", value: "NONE" }
      ];
    case "ca":
      return [
        { label: "Avió", value: "PLANE" },
        { label: "Vaixell", value: "BOAT" },
        { label: "Sense transport", value: "NONE" }
      ];
    default:
      return [
        { label: "Plane", value: "PLANE" },
        { label: "Ferry", value: "BOAT" },
        { label: "No transport", value: "NONE" }
      ];
  }
};
