<template>
  <b-card :title="$t('Security deposit')">
    <b-alert v-if="booking.active" show variant="secondary" class="mb-4">
      <div class="alert-body">
        <feather-icon class="mr-25" icon="InfoIcon" />
        <span v-if="hasChargableSecurityDeposit">
          {{ $t("pages.booking.chargableSecurityDepositInfo") }}
        </span>
        <span v-else>
          {{ $t("pages.booking.ownerChargableSecurityDepositInfo") }}
        </span>
      </div>
    </b-alert>

    <b-row>
      <b-col cols="12">
        <div class="d-flex justify-content-between font-weight-bold mb-3">
          <span>
            {{ $t("Amount") }}
          </span>
          <span>
            {{ formatCurrency(securityDepositPrice) }}
          </span>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="securityDepositChargedPrice > 0">
      <b-col cols="12">
        <div class="d-flex justify-content-between font-weight-bold mb-3">
          <span>
            {{ $t("Paid") }}
          </span>
          <span>
            {{ formatCurrency(securityDepositChargedPrice) }}
          </span>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="securityDepositRefundedPrice > 0">
      <b-col cols="12">
        <div class="d-flex justify-content-between font-weight-bold mb-3">
          <span>
            {{ $t("Refunded") }}
          </span>
          <span>
            {{ formatCurrency(securityDepositRefundedPrice) }}
          </span>
        </div>
      </b-col>
    </b-row>
    <b-row v-if="securityDepositPendingPrice > 0">
      <b-col cols="12">
        <div class="d-flex justify-content-between font-weight-bold mb-3">
          <span>
            {{ $t("Pending") }}
          </span>
          <span>
            {{ formatCurrency(securityDepositPendingPrice) }}
          </span>
        </div>
      </b-col>
    </b-row>

    <!--BUTTON-->
    <b-row
      v-if="
        booking.active &&
          hasChargableSecurityDeposit &&
          securityDepositPendingPrice > 0
      "
      class="mt-3 d-flex justify-content-end"
    >
      <b-col cols="12" md="auto">
        <b-button variant="primary" block target="blank" :href="paymentLink">
          <feather-icon icon="CreditCardIcon" class="mr-1" />
          <span>
            {{ $t("Pay now") }}
          </span>
        </b-button>
      </b-col>
    </b-row>
    <!--END BUTTON-->
  </b-card>
</template>

<script>
import { BCard, BAlert, BButton, BRow, BCol } from "bootstrap-vue";
import {
  formatCurrency,
  formatDateStringToDatabaseDate
} from "@/utils/formatters";
import { getTpvLink } from "@/utils/methods";

export default {
  components: {
    BButton,
    BAlert,
    BCard,
    BRow,
    BCol
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    hasChargableSecurityDeposit() {
      return this.$store.getters["booking/hasChargableSecurityDeposit"];
    },
    securityDepositPrice() {
      return this.$store.getters["booking/securityDepositPrice"];
    },
    securityDepositChargedPrice() {
      return this.$store.getters["booking/securityDepositChargedPrice"];
    },
    securityDepositRefundedPrice() {
      return this.$store.getters["booking/securityDepositRefundedPrice"];
    },
    securityDepositPendingPrice() {
      return this.$store.getters["booking/securityDepositPendingPrice"];
    },
    hasManagedSecurityDeposit() {
      return this.$store.getters["booking/hasManagedSecurityDeposit"];
    },
    paymentLink() {
      const { localizator, accommodation, checkin, checkout } =
        this.booking || {};
      const amount = (this.securityDepositPendingPrice / 100).toFixed(2);
      const comment = `${this.$t(
        "Security deposit",
        this.$i18n.locale
      )} ${localizator}`;
      const accommodationName = accommodation?.name || null;
      const checkinDate = formatDateStringToDatabaseDate(checkin);
      const checkoutDate = formatDateStringToDatabaseDate(checkout);

      return getTpvLink({
        locale: this.$i18n.locale,
        amount,
        comment,
        accommodation: accommodationName,
        localizator,
        checkin: checkinDate,
        checkout: checkoutDate
      });
    }
  },
  methods: {
    formatCurrency(price) {
      return formatCurrency(price);
    }
  }
};
</script>
