var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading,"variant":"white","opacity":0.9,"rounded":"false"}},[_c('validation-observer',{ref:"schedulesFormRules"},[_c('h4',[_vm._v(_vm._s(_vm.$t("pages.booking.schedulesAndTransport")))]),_c('b-form',{attrs:{"id":"schedules-form"}},[_c('h5',{staticClass:"mt-4"},[_vm._v(" "+_vm._s(_vm.$t("Arrival"))+" ")]),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('pages.booking.accommodationArrivalTime'),"label-for":"accommodation-arrival-time"}},[_c('validation-provider',{attrs:{"name":"accommodationArrivalTime","rules":"required|checkin"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-timepicker',{staticClass:"mb-2",attrs:{"id":"accommodation-arrival-time","label-no-time-selected":"","locale":_vm.timepickerLocale,"minutes-step":"10","state":errors.length > 0 ? false : null,"hide-header":"","no-close-button":""},model:{value:(_vm.accommodationArrivalTime),callback:function ($$v) {_vm.accommodationArrivalTime=$$v},expression:"accommodationArrivalTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('pages.booking.modeOfTransport'),"label-for":"arrival-transport"}},[_c('v-select',{attrs:{"id":"arrival-transport","options":_vm.transportMethodOptions,"reduce":function (option) { return option.value; },"clearable":false},model:{value:(_vm.arrivalTransport),callback:function ($$v) {_vm.arrivalTransport=$$v},expression:"arrivalTransport"}})],1)],1),(_vm.arrivalTransport === 'PLANE')?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{ref:"arrivalTransportLocalizatorFormGroup",attrs:{"label":_vm.$t('Flight number'),"label-for":"arrival-transport-localizator"}},[_c('validation-provider',{attrs:{"name":"arrivalFlightNumber","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-2",attrs:{"id":"arrival-transport-localizator","trim":"","formatter":function (value) { return value.toUpperCase(); },"state":errors.length > 0 ? false : null},model:{value:(_vm.arrivalTransportLocalizator),callback:function ($$v) {_vm.arrivalTransportLocalizator=$$v},expression:"arrivalTransportLocalizator"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2219470810)})],1)],1):_vm._e(),(_vm.arrivalTransport === 'BOAT')?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{ref:"transportArrivalTimeFormGroup",attrs:{"label":_vm.$t('Arrival time'),"label-for":"transport-arrival-time"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Arrival time'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-timepicker',{staticClass:"mb-2",attrs:{"id":"transport-arrival-time","label-no-time-selected":"","locale":_vm.timepickerLocale,"minutes-step":"10","hide-header":"","no-close-button":"","state":errors.length > 0 ? false : null},model:{value:(_vm.transportArrivalTime),callback:function ($$v) {_vm.transportArrivalTime=$$v},expression:"transportArrivalTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1948502559)})],1)],1):_vm._e()],1),_c('h5',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(_vm.$t("Departure"))+" ")]),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('pages.booking.accommodationDepartureTime'),"label-for":"accommodation-departure-time"}},[_c('validation-provider',{attrs:{"name":"accommodationDepartureTime","rules":"required|checkout"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-timepicker',{staticClass:"mb-2",attrs:{"id":"accommodation-departure-time","label-no-time-selected":"","locale":_vm.timepickerLocale,"minutes-step":"10","state":errors.length > 0 ? false : null,"hide-header":"","no-close-button":""},model:{value:(_vm.accommodationDepartureTime),callback:function ($$v) {_vm.accommodationDepartureTime=$$v},expression:"accommodationDepartureTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('pages.booking.modeOfTransport'),"label-for":"departure-transport"}},[_c('v-select',{attrs:{"id":"departure-transport","options":_vm.transportMethodOptions,"reduce":function (option) { return option.value; },"clearable":false},model:{value:(_vm.departureTransport),callback:function ($$v) {_vm.departureTransport=$$v},expression:"departureTransport"}})],1)],1),(_vm.departureTransport === 'PLANE')?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{ref:"departureTransportLocalizatorFormGroup",attrs:{"label":_vm.$t('Flight number'),"label-for":"departure-transport-localizator"}},[_c('b-form-input',{staticClass:"mb-2",attrs:{"id":"departure-transport-localizator","trim":"","formatter":function (value) { return value.toUpperCase(); }},model:{value:(_vm.departureTransportLocalizator),callback:function ($$v) {_vm.departureTransportLocalizator=$$v},expression:"departureTransportLocalizator"}})],1)],1):_vm._e(),(_vm.departureTransport === 'BOAT')?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{ref:"transportDepartureTimeFormGroup",attrs:{"label":_vm.$t('Departure time'),"label-for":"transport-departure-time"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Departure time'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-timepicker',{staticClass:"mb-2",attrs:{"id":"transport-departure-time","label-no-time-selected":"","locale":_vm.timepickerLocale,"minutes-step":"10","hide-header":"","no-close-button":"","state":errors.length > 0 ? false : null},model:{value:(_vm.transportDepartureTime),callback:function ($$v) {_vm.transportDepartureTime=$$v},expression:"transportDepartureTime"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4204923775)})],1)],1):_vm._e()],1),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.onSaveButtonClicked}},[_vm._v(" "+_vm._s(_vm.$t("Save"))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }