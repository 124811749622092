<template>
  <b-card
    id="checkin-status-card"
    class="border"
    :class="{
      'border-success': onlineCheckinCompleted,
      'border-warning': !onlineCheckinCompleted
    }"
  >
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between justify-content-md-around">
          <div
            class="d-flex flex-column justify-content-between align-items-center  text-center checkin-status-item bg-lighten-4"
            :class="clientBgColor"
          >
            <b-img
              class="d-none d-md-block"
              src="@/assets/foravila/images/illustrations/add-user.svg"
            />
            <div class="p-3 p-md-0">
              <div class="fr-checkin-status-card-number-size font-weight-bold">
                1
              </div>
              <h5 class="fr-checkin-status-card-title-size">
                {{ $t("Client") }}
              </h5>
            </div>
            <b-badge
              :variant="clientBadgeVariant"
              class="fr-checkin-status-card-badge-size d-none d-md-block"
            >
              {{ clientBadgeText }}
            </b-badge>
          </div>
          <div
            class="d-flex flex-column align-items-center justify-content-between mx-3 mx-md-0 text-center checkin-status-item bg-lighten-4"
            :class="guestsBgColor"
          >
            <b-img
              class="d-none d-md-block"
              src="@/assets/foravila/images/illustrations/guests.svg"
            />
            <div class="p-3 p-md-0">
              <div class="fr-checkin-status-card-number-size font-weight-bold">
                2
              </div>
              <h5 class="fr-checkin-status-card-title-size">
                {{ $t("Guests") }}
              </h5>
            </div>
            <b-badge
              :variant="guestsBadgeVariant"
              class="fr-checkin-status-card-badge-size d-none d-md-block"
            >
              {{ guestsBadgeText }}
            </b-badge>
          </div>
          <div
            class="d-flex flex-column align-items-center justify-content-between text-center checkin-status-item bg-lighten-4"
            :class="arrivalBgColor"
          >
            <b-img
              class="d-none d-md-block"
              src="@/assets/foravila/images/illustrations/arrival.svg"
            />
            <div class="p-3 p-md-0">
              <div class="fr-checkin-status-card-number-size font-weight-bold">
                3
              </div>
              <h5 class="fr-checkin-status-card-title-size">
                {{ $t("Schedules") }}
              </h5>
            </div>
            <b-badge
              :variant="arrivalBadgeVariant"
              class=" fr-checkin-status-card-badge-size d-none d-md-block"
            >
              {{ arrivalBadgeText }}
            </b-badge>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-4 mt-md-5 text-center d-flex justify-content-center">
      <b-col cols="12" class="mb-4">
        <div class="bg-warning text-white p-4">
          <p class="fr-checkin-status-card-text-size mb-0">
            {{ mainText }}
          </p>
        </div>
      </b-col>
      <b-col v-if="onlineCheckinCompleted" cols="12" sm="auto">
        <b-button v-b-modal.edit-checkin-modal block variant="outline-primary">
          {{ $t("Contact us") }}
        </b-button>
      </b-col>
      <b-col
        v-if="onlineCheckinCompleted"
        cols="12"
        sm="auto"
        class="mt-3 mt-sm-0"
      >
        <b-button
          block
          variant="primary"
          @click="onArrivalInstructionsButtonClicked"
        >
          {{ $t("Arrival instructions") }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BBadge,
  BImg,
  BRow,
  BCol,
  BButton,
  VBModal
} from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BImg,
    BButton
  },
  directives: {
    "b-modal": VBModal
  },
  data() {
    return {
      INSTRUCTIONS_TAB_INDEX: 2
    };
  },
  computed: {
    guests() {
      return this.$store.getters["booking/guests"];
    },
    onlineCheckinCompleted() {
      return this.$store.getters["booking/onlineCheckinCompleted"];
    },
    clientOnlineCheckinCompleted() {
      return this.$store.getters["booking/clientOnlineCheckinCompleted"];
    },
    guestsOnlineCheckinCompleted() {
      return this.$store.getters["booking/guestsOnlineCheckinCompleted"];
    },
    arrivalOnlineCheckinCompleted() {
      return this.$store.getters["booking/arrivalOnlineCheckinCompleted"];
    },
    clientBadgeText() {
      return this.clientOnlineCheckinCompleted
        ? this.$t("Complete-m")
        : this.$t("Incomplete-m");
    },
    clientBadgeVariant() {
      return this.clientOnlineCheckinCompleted
        ? "light-success"
        : "light-warning";
    },
    clientBgColor() {
      return this.clientOnlineCheckinCompleted ? "bg-success" : "bg-warning";
    },
    guestsBadgeText() {
      if (!this.guests.length)
        return this.$t("pages.booking.checkin.noGuestsRegistered");
      return this.guestsOnlineCheckinCompleted
        ? this.$t("Complete-m")
        : this.$t("Incomplete-m");
    },
    guestsBadgeVariant() {
      return this.guestsOnlineCheckinCompleted
        ? "light-success"
        : "light-warning";
    },
    guestsBgColor() {
      return this.guestsOnlineCheckinCompleted ? "bg-success" : "bg-warning";
    },
    arrivalBadgeText() {
      return this.arrivalOnlineCheckinCompleted
        ? this.$t("Complete-m")
        : this.$t("Incomplete-m");
    },
    arrivalBadgeVariant() {
      return this.arrivalOnlineCheckinCompleted
        ? "light-success"
        : "light-warning";
    },
    arrivalBgColor() {
      return this.arrivalOnlineCheckinCompleted ? "bg-success" : "bg-warning";
    },
    mainText() {
      return this.onlineCheckinCompleted
        ? this.$t("pages.booking.checkin.completed")
        : this.$t("pages.booking.checkin.notCompleted");
    }
  },
  methods: {
    onArrivalInstructionsButtonClicked() {
      this.$store.dispatch("app/setBookingTab", this.INSTRUCTIONS_TAB_INDEX);
    }
  }
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/include";
#checkin-status-card {
  img {
    height: 60px;
    margin-top: 20px;
  }
  @include media-breakpoint-up(md) {
    img {
      height: 160px;
    }
    .checkin-status-item {
      flex: 0 1 0;
      background-color: transparent !important;
      border-radius: 0 !important;
      padding: 0 !important;
      color: $dark;
    }
  }
  @include media-breakpoint-up(lg) {
    img {
      height: 240px;
    }
  }
  .checkin-status-item {
    flex: 1 1 0;
    border-radius: 0.5rem;
  }
}
</style>
