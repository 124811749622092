<template>
  <div id="airport-transfer-sidebar">
    <airport-transfer-sidebar-content
      v-if="serviceType === 'AIRPORT_TRANSFER'"
      :service="bookingService"
    />
  </div>
</template>

<script>
import AirportTransferSidebarContent from '@/views/foravila/bookings/booking/components/services/AirportTransferSidebarContent.vue'

export default {
  components: {
    AirportTransferSidebarContent,
  },
  props: {
    bookingService: {
      type: [Object, null],
      default: null,
    },
  },
  computed: {
    serviceType() {
      return this.bookingService?.service?.code || null
    },
  },
}
</script>
