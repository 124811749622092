<template>
  <b-modal
    id="edit-checkin-modal"
    :title="$t('pages.booking.checkin.editCheckinInformation')"
    :ok-title="$t('Send')"
    :cancel-title="$t('Cancel')"
    centered
    :ok-disabled="!message"
    @ok="onSendButtonClicked()"
  >
    <p>
      {{ $t("pages.booking.checkin.editCheckinInformationDescription") }}
    </p>
    <b-form-textarea
      v-model="message"
      :placeholder="
        $t('pages.booking.checkin.editCheckinInformationPlaceholder')
      "
      rows="4"
      no-resize
    />
  </b-modal>
</template>

<script>
import { BModal, BFormTextarea } from "bootstrap-vue";
import axios from "@/axios";

export default {
  components: {
    BFormTextarea,
    BModal
  },
  data() {
    return {
      message: null
    };
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    subject() {
      return `Sol·licitud de modificació de reserva ${this.booking?.localizator}`;
    },
    requestContent() {
      return {
        locale: this.$i18n.locale,
        subject: this.subject,
        localizator: this.booking?.localizator,
        message: this.message
      };
    }
  },
  methods: {
    onSendButtonClicked() {
      this.$store.dispatch("app/setLoading", true);
      axios
        .post(
          `/mail/agency/booking-modification-request/${this.requestContent.locale}`,
          this.requestContent
        )
        .then(() => {
          this.$store.dispatch("app/setLoading", false);
          this.message = null;
          this.$bvModal.hide("edit-checkin-modal");
          this.$swal({
            icon: "success",
            title: this.$t("pages.booking.requestModificationSuccessTitle"),
            text: this.$t("pages.booking.requestModificationSuccessText"),
            customClass: {
              confirmButton: "btn btn-success"
            }
          });
        })
        .catch(() => {
          this.$store.dispatch("app/setLoading", false);
          this.$swal({
            icon: "error",
            title: this.$t("errors.requestBookingModification.title"),
            text: this.$t("errors.requestBookingModification.text"),
            customClass: {
              confirmButton: "btn btn-danger"
            }
          });
        });
    }
  }
};
</script>
