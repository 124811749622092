<template>
  <b-card :title="$t('Optional accommodation extras')">
    <b-form>
      <b-row>
        <b-col
          v-for="bookingService in hiredAccommodationExtras"
          :key="`booking-service-${bookingService.uuid}`"
          cols="12"
          sm="6"
          md="4"
          xl="3"
        >
          <service-item
            :service="bookingService"
            :price="getBookingServicePrice(bookingService)"
            :hired="true"
          />
        </b-col>
        <b-col
          v-for="accommodationService in notHiredAccommodationExtras"
          :key="`optional-accommodation-service-${accommodationService.uuid}-${renderKey}`"
          cols="12"
          sm="6"
          md="4"
          xl="3"
        >
          <service-item
            :service="accommodationService"
            :price="getAccommodationServicePrice(accommodationService)"
            :hired="false"
            @selected="onServiceSelected"
          />
        </b-col>
      </b-row>

      <b-row class="d-flex justify-content-end">
        <b-col
          cols="12"
          md="4"
          lg="3"
        >
          <b-button
            class="mt-4"
            variant="primary"
            block
            :disabled="!selectedServices.length"
            @click="confirmRequestServices"
          >
            <feather-icon
              icon="BellIcon"
              class="mr-1"
            />
            {{ $t('Request extras') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BForm,
} from 'bootstrap-vue'
import ServiceItem from '@/views/foravila/bookings/booking/components/summary/ServiceItem.vue'
import {
  getAccommodationServiceClientUnitPriceText,
  getBookingServiceClientTotalPriceText,
  getPaymentTimeName,
  getServiceName,
} from '@/utils/methods'
import axios from '@/axios'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    ServiceItem,
    BForm,
  },
  props: {
    isSummary: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedServices: [],
      // Using a dynamic render key we can reset the components by simply updating this value
      renderKey: 0,
    }
  },
  computed: {
    booking() {
      return this.$store.getters['booking/booking']
    },
    accommodation() {
      return this.$store.getters['booking/accommodation']
    },
    nights() {
      return this.booking?.nights || null
    },
    bookingServices() {
      return this.$store.getters['booking/services']
    },
    hiredAccommodationExtras() {
      return this.$store.getters['booking/hiredAccommodationExtras']
    },
    optionalAccommodationExtras() {
      return this.$store.getters['booking/optionalAccommodationExtras']
    },
    notHiredAccommodationExtras() {
      if (!this.optionalAccommodationExtras.length) return []
      return this.optionalAccommodationExtras.filter(accommodationService => !this.isHired(accommodationService))
    },
    subject() {
      return `Sol·licitud de serveis extra per la reserva ${this.booking?.localizator}`
    },
    parsedSelectedServices() {
      if (!this.selectedServices.length) return []
      return this.selectedServices.map(accommodationService => ({
        name: getServiceName(accommodationService.service.code, 'ca-ES'),
        price: getAccommodationServiceClientUnitPriceText(accommodationService, 'ca-ES'),
      }))
    },
    requestContent() {
      return {
        locale: this.$i18n.locale,
        subject: this.subject,
        localizator: this.booking?.localizator,
        requestedServices: this.parsedSelectedServices,
      }
    },
  },
  methods: {
    isHired(accommodationService) {
      return !!this.bookingServices.find(bookingService => bookingService.service.code === accommodationService.service.code)
    },
    getAccommodationServicePrice(accommodationService) {
      return getAccommodationServiceClientUnitPriceText(accommodationService, this.$i18n.locale)
    },
    getBookingServicePrice(bookingService) {
      return getBookingServiceClientTotalPriceText(bookingService, this.nights, this.$i18n.locale)
    },
    getServicePaymentTime(service) {
      return getPaymentTimeName(service?.paymentTime, this.$i18n.locale)
    },
    confirmRequestServices() {
      // Create the html <li>'s tags
      const requestedServicesList = []
      this.selectedServices.forEach(accommodationService => {
        requestedServicesList.push(`
          <li>${getServiceName(accommodationService.service.code, this.$i18n.locale)} <small>${getAccommodationServiceClientUnitPriceText(accommodationService, this.$i18n.locale)}</small></li>
        `)
      })

      this.$swal({
        title: `<span class="font-weight-bolder h3">${this.$t('pages.booking.requestServicesPromptTitle')}:</span>`,
        html: `<ul class="list-unstyled">${requestedServicesList.join('')}<ul>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Request'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) this.requestServices()
      })
    },
    requestServices() {
      this.$store.dispatch('app/setLoading', true)
      axios
        .post(`/mail/agency/booking-services-request/${this.requestContent.locale}`, this.requestContent)
        .then(() => {
          this.$store.dispatch('app/setLoading', false)
          this.renderKey += 1
          this.$swal({
            icon: 'success',
            title: this.$t('pages.booking.requestServicesSuccessTitle'),
            text: this.$t('pages.booking.requestServicesSuccessText'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        })
        .catch(() => {
          this.$store.dispatch('app/setLoading', false)
          this.$swal({
            icon: 'error',
            title: this.$t('errors.requestBookingServices.title'),
            text: this.$t('errors.requestBookingServices.text'),
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
    onServiceSelected(data) {
      if (data.selected) {
        const serviceIndex = this.selectedServices.findIndex(accommodationService => accommodationService.service.code === data.service.service.code)
        if (serviceIndex === -1) this.selectedServices.push(data.service)
      } else {
        const serviceIndex = this.selectedServices.findIndex(accommodationService => accommodationService.service.code === data.service.service.code)
        if (serviceIndex !== -1) this.selectedServices.splice(serviceIndex, 1)
      }
    },
  },
}
</script>
