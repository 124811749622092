<template>
  <div id="booking-view">
    <b-row v-if="loading || !booking">
      <b-col cols="12">
        <booking-info-placeholder-card />
      </b-col>
    </b-row>
    <template v-else>
      <vue-horizontal class="mb-3">
        <template v-slot:btn-prev>
          <feather-icon
            size="24"
            height="40"
            class="btn-left"
            icon="ChevronLeftIcon"
          />
        </template>
        <template v-slot:btn-next>
          <feather-icon
            size="24"
            height="40"
            class="btn-right"
            icon="ChevronRightIcon"
          />
        </template>
        <div @click="selectedTab = 0" :class="getTabClass(selectedTab, 0)">
          <feather-icon class="mr-1" icon="ClipboardIcon" />
          <span>
            {{ $t("Summary") }}
          </span>
        </div>
        <div
          v-if="showOnlineCheckinTab"
          @click="selectedTab = 1"
          :class="getTabClass(selectedTab, 1)"
        >
          <feather-icon class="m-1" icon="UsersIcon" />
          <span>
            {{ $t("Online check-in") }}
          </span>
        </div>
        <div
          v-if="showInstructionsTab"
          @click="selectedTab = 2"
          :class="getTabClass(selectedTab, 2)"
        >
          <feather-icon class="m-1" icon="InfoIcon" />
          <span>
            {{ $t("Instructions") }}
          </span>
        </div>
        <div
          v-if="showServicesTab"
          @click="selectedTab = 3"
          :class="getTabClass(selectedTab, 3)"
        >
          <feather-icon class="m-1" icon="BriefcaseIcon" />
          <span> {{ $t("Services") }} </span>
        </div>
        <div
          v-if="showReviewTab"
          @click="selectedTab = 4"
          :class="getTabClass(selectedTab, 4)"
        >
          <feather-icon class="m-1" icon="AwardIcon" />
          <span>
            {{ $t("Review") }}
          </span>
        </div>
      </vue-horizontal>

      <b-tabs v-model="selectedTab" lazy nav-wrapper-class="d-none">
        <b-tab active class="mb-2">
          <b-row>
            <b-col cols="12">
              <booking-info-card />
            </b-col>
            <b-col v-if="showTransferBanner" cols="12">
              <airport-transfer-service-banner />
            </b-col>
            <b-col v-if="showPriceDetailsCard" cols="12">
              <price-details-card />
            </b-col>
            <b-col v-if="showPaymentsCard" cols="12">
              <payments-card />
            </b-col>
            <b-col v-if="showSecurityDepositCard" cols="12">
              <security-deposit-card />
            </b-col>
            <b-col v-if="optionalAccommodationExtras.length" cols="12">
              <optional-accommodation-extras-card />
            </b-col>
            <b-col v-if="isOwnReservation && policy" cols="12">
              <conditions-card />
            </b-col>
          </b-row>
        </b-tab>

        <b-tab v-if="showOnlineCheckinTab">
          <b-row>
            <b-col cols="12">
              <checkin-status-card />
            </b-col>
            <b-col cols="12">
              <client-card />
            </b-col>
            <b-col cols="12">
              <guests-card />
            </b-col>
            <b-col cols="12">
              <schedules-transport-card />
            </b-col>
          </b-row>
          <edit-checkin-modal />
        </b-tab>

        <b-tab v-if="showInstructionsTab">
          <b-row>
            <b-col cols="12">
              <instructions-card />
            </b-col>
          </b-row>
        </b-tab>

        <b-tab v-if="showServicesTab">
          <b-row>
            <b-col>
              <third-party-services-card />
            </b-col>
          </b-row>
        </b-tab>

        <b-tab v-if="showReviewTab">
          <b-row>
            <b-col cols="12">
              <review-card />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </template>
  </div>
</template>

<script>
import { BRow, BCol, BTab, BTabs } from "bootstrap-vue";
import BookingInfoCard from "@/views/foravila/bookings/booking/components/summary/BookingInfoCard.vue";
import BookingInfoPlaceholderCard from "@/views/foravila/bookings/booking/components/summary/BookingInfoPlaceholderCard.vue";
import PaymentsCard from "@/views/foravila/bookings/booking/components/summary/PaymentsCard.vue";
import PriceDetailsCard from "@/views/foravila/bookings/booking/components/summary/PriceDetailsCard.vue";
import SecurityDepositCard from "@/views/foravila/bookings/booking/components/summary/SecurityDepositCard.vue";
import OptionalAccommodationExtrasCard from "@/views/foravila/bookings/booking/components/summary/OptionalAccommodationExtrasCard.vue";
import ConditionsCard from "@/views/foravila/bookings/booking/components/summary/ConditionsCard.vue";
import ThirdPartyServicesCard from "@/views/foravila/bookings/booking/components/services/ThirdPartyServicesCard.vue";
import ClientCard from "@/views/foravila/bookings/booking/components/checkin/ClientCard.vue";
import GuestsCard from "@/views/foravila/bookings/booking/components/checkin/GuestsCard.vue";
import SchedulesTransportCard from "@/views/foravila/bookings/booking/components/checkin/SchedulesTransportCard.vue";
import InstructionsCard from "@/views/foravila/bookings/booking/components/instructions/InstructionsCard.vue";
import ReviewCard from "@/views/foravila/bookings/booking/components/review/ReviewCard.vue";
import EditCheckinModal from "@/views/foravila/bookings/booking/components/checkin/EditCheckinModal.vue";
import { notifyError, scrollToId } from "@/utils/methods";
import { AGENCY_CODE } from "@appConfig";
import CheckinStatusCard from "@/views/foravila/bookings/booking/components/checkin/CheckinStatusCard.vue";
import AirportTransferServiceBanner from "@/views/foravila/bookings/list/components/AirportTransferServiceBanner.vue";
import VueHorizontal from "vue-horizontal";

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BTabs,
    ThirdPartyServicesCard,
    AirportTransferServiceBanner,
    BookingInfoCard,
    BookingInfoPlaceholderCard,
    PriceDetailsCard,
    SecurityDepositCard,
    OptionalAccommodationExtrasCard,
    ConditionsCard,
    PaymentsCard,
    ClientCard,
    GuestsCard,
    SchedulesTransportCard,
    ReviewCard,
    InstructionsCard,
    CheckinStatusCard,
    EditCheckinModal,
    VueHorizontal
  },
  computed: {
    selectedTab: {
      get() {
        return this.$store.getters["app/bookingTab"];
      },
      set(tabIndex) {
        this.$store.dispatch("app/setBookingTab", tabIndex);
      }
    },
    localizator() {
      return this.$route?.params?.localizator || null;
    },
    loading() {
      return this.loadingClient || this.loadingBooking;
    },
    userData() {
      return JSON.parse(localStorage.getItem("foravilaGuestsUserData"));
    },
    clientUuid() {
      return this.userData.client.uuid;
    },
    loadingClient() {
      return this.$store.getters["client/loading"];
    },
    client() {
      return this.$store.getters["client/client"];
    },
    loadingBooking() {
      return this.$store.getters["booking/loading"];
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    accommodation() {
      return this.$store.getters["booking/accommodation"];
    },
    optionalAccommodationExtras() {
      return this.$store.getters["booking/optionalAccommodationExtras"];
    },
    hasManagedPayment() {
      return this.$store.getters["booking/hasManagedPayment"];
    },
    hasSecurityDeposit() {
      return this.$store.getters["booking/hasSecurityDeposit"];
    },
    hasManagedSecurityDeposit() {
      return this.$store.getters["booking/hasManagedSecurityDeposit"];
    },
    onlineCheckinCompleted() {
      return this.$store.getters["booking/onlineCheckinCompleted"];
    },
    hiredThirdPartyServices() {
      const services = this.$store.getters["booking/hiredThirdPartyServices"];

      // TODO: update the following array with the allowed services
      const allowedServices = ["AIRPORT_TRANSFER"];
      return services.filter(bookingService =>
        allowedServices.includes(bookingService.service?.code)
      );
    },
    optionalAccommodationThirdPartyServices() {
      const services = this.$store.getters[
        "booking/optionalAccommodationThirdPartyServices"
      ];

      // TODO: update the following array with the allowed services
      const allowedServices = ["AIRPORT_TRANSFER"];
      return services.filter(accommodationService =>
        allowedServices.includes(accommodationService.service?.code)
      );
    },
    loadingGlobalServices() {
      return this.$store.getters["services/loadingGlobalServices"];
    },
    globalServices() {
      return this.$store.getters["services/globalServices"];
    },
    isOwnReservation() {
      if (!this.booking) return null;
      if (!this.booking.source) return true;
      return this.booking.source === AGENCY_CODE;
    },
    policy() {
      return this.$store.getters["booking/policy"];
    },
    showOnlineCheckinTab() {
      if (!this.booking) return false;
      return !this.loading && this.booking.confirmed;
    },
    showInstructionsTab() {
      if (!this.booking) return false;
      return !this.loading && this.booking.confirmed;
    },
    showServicesTab() {
      if (!this.booking) return false;
      return (
        !this.loading &&
        this.booking.confirmed &&
        (this.hiredThirdPartyServices.length ||
          this.optionalAccommodationThirdPartyServices.length)
      );
    },
    showReviewTab() {
      if (!this.booking) return false;

      // Only show the review tab if the guest has stayed in at leas 1 night
      const dayAfterCheckin = new Date(this.booking.checkin);
      dayAfterCheckin.setDate(dayAfterCheckin.getDate() + 1);
      const today = new Date();

      return (
        !this.loading &&
        this.booking.active &&
        this.onlineCheckinCompleted &&
        today >= dayAfterCheckin
      );
    },
    showPriceDetailsCard() {
      if (!this.booking || this.booking.cancelled) {
        return false;
      }
      return !this.hasManagedPayment;
    },
    showPaymentsCard() {
      if (!this.booking || this.booking.cancelled) {
        return false;
      }
      return !this.hasManagedPayment;
    },
    showSecurityDepositCard() {
      if (!this.booking || this.booking.cancelled) {
        return false;
      }
      return this.hasSecurityDeposit && !this.hasManagedSecurityDeposit;
    },
    showTransferBanner() {
      if (!this.booking || this.booking.cancelled) {
        return false;
      }
      return !this.hiredThirdPartyServices.find(
        bookingService => bookingService.service?.code === "AIRPORT_TRANSFER"
      );
    }
  },
  watch: {
    selectedTab() {
      scrollToId("booking-page-tabs");
    }
  },
  created() {
    if (!this.client) {
      this.fetchClient();
    }

    this.fetchBooking();

    if (!this.globalServices?.length && !this.loadingGlobalServices) {
      this.fetchGlobalServices();
    }
  },
  mounted() {
    this.selectedTab = this.$store.getters["app/bookingTab"];
  },
  beforeDestroy() {
    this.$store.dispatch("booking/reset");
    this.$store.dispatch("app/setBookingTab", 0);
  },
  methods: {
    getTabClass(selectedIndex, index) {
      return `font-weight-bold pb-3 cursor-pointer px-3 ${
        selectedIndex === index ? "btn-active" : ""
      }`;
    },
    fetchBooking() {
      if (!this.localizator) return;
      this.$store
        .dispatch("booking/fetchBookingByLocalizator", this.localizator)
        .then(booking => {
          // Once booking is fetched, fetch the rest of the info
          this.$store.dispatch("booking/fetchClientPayments", booking.uuid);
          this.$store.dispatch("booking/fetchGuests", booking.uuid);

          if (this.accommodation) {
            this.$store.dispatch(
              "booking/fetchAccommodationServices",
              this.accommodation.uuid
            );
          }
        })
        .catch(() =>
          notifyError(
            this.$t("errors.fetchBooking.title"),
            this.$t("errors.fetchBooking.text")
          )
        );
    },
    fetchClient() {
      this.$store
        .dispatch("client/fetchClient", this.clientUuid)
        .catch(() =>
          notifyError(
            this.$t("errors.fetchBookings.title"),
            this.$t("errors.fetchBookings.text")
          )
        );
    },
    fetchGlobalServices() {
      this.$store
        .dispatch("services/fetchGlobalServices", { pagination: false })
        .catch(() => {});
    }
  }
};
</script>

<style lang="scss">
#booking-view {
  .v-hl-btn {
    top: -14px;
  }
  .btn-left {
    background: linear-gradient(to left, #ffffff00 0, #f8f8f8 50%, #f8f8f8);
  }
  .btn-right {
    background: linear-gradient(to right, #ffffff00 0, #f8f8f8 50%, #f8f8f8);
  }
  .btn-active {
    color: #ffcd00;
    border-bottom: 2px solid;
  }
}
</style>
