<template>
  <div class="p-4">
    <!-- ARRIVAL TRANSFER -->
    <template>
      <div
        class="d-flex justify-content-between align-items-top font-weight-bold"
      >
        <div>
          {{ $t("pages.booking.services.airportTransfer.title") }}
        </div>
        <div>
          {{ basePrice }}
        </div>
      </div>
      <div class="mt-3 text-body">
        <div>
          <div>
            <small>
              {{ $t("Pickup location") }}
            </small>
          </div>
          <span v-if="pickupLocationName" class="font-weight-bold">
            {{ pickupLocationName }}
          </span>
          <div
            v-if="pickupLocationAddress"
            class="bg-light p-3"
            :class="{ 'mt-2': pickupLocationName }"
          >
            <small>
              {{ pickupLocationAddress }}
            </small>
          </div>
        </div>
      </div>
      <div class="mt-3 text-body">
        <div class="mt-3 mt-md-0">
          <div>
            <small>
              {{ $t("Dropoff location") }}
            </small>
          </div>
          <span v-if="dropoffLocationName" class="font-weight-bold">
            {{ dropoffLocationName }}
          </span>
          <div
            v-if="dropoffLocationAddress"
            class="bg-light p-3"
            :class="{ 'mt-2': dropoffLocationName }"
          >
            <small>
              {{ dropoffLocationAddress }}
            </small>
          </div>
        </div>
      </div>
      <div class="mt-3 text-body">
        <div>
          <small>
            {{ $t("Date and time") }}
          </small>
        </div>
        <span class="font-weight-bold">
          {{ pickupDateTime }}
        </span>
      </div>
      <div class="mt-3 text-body">
        <div>
          <small>
            {{ $t("Passengers") }}
          </small>
        </div>
        <span class="font-weight-bold">
          {{ passengers }}
        </span>
      </div>
      <div class="dashed-divider my-4" />
    </template>

    <!-- EXTRAS -->
    <div
      class="d-flex justify-content-between align-items-center mt-1 font-weight-bold"
    >
      <div>
        {{ $t("Extras") }}
      </div>
      <div>
        {{ extrasPrice }}
      </div>
    </div>

    <div
      v-for="(extra, extraIndex) in extras"
      :key="`extra-${extraIndex}`"
      class="mt-1"
    >
      <span class=" mr-2">
        <template v-if="extra.amount">
          <small class="mr-1">{{ extra.amount }}</small>
          <small>x</small>
        </template>
        <small v-if="extra.name">
          {{ extra.name }}
        </small>
      </span>
    </div>

    <div
      class="d-flex justify-content-between align-items-center bg-primary bg-lighten-5 px-3 py-4 mt-4"
    >
      <div class="font-weight-bold h5 mb-0">
        {{ $t("Total") }}
      </div>
      <div class="h3 font-weight-bolder mb-0">
        {{ pvpPrice }}
      </div>
    </div>
  </div>
</template>

<script>
import { getFullAddress } from "@/utils/methods";
import { formatCurrency, formatDateStringToDate } from "@/utils/formatters";

export default {
  props: {
    service: {
      type: Object,
      required: true
    }
  },
  computed: {
    transfer() {
      return this.service?.details || null;
    },
    basePrice() {
      return formatCurrency(this.transfer?.basePrice) || null;
    },
    extrasPrice() {
      return formatCurrency(this.transfer?.extrasPrice) || null;
    },
    pvpPrice() {
      return formatCurrency(this.transfer?.pvpPrice) || null;
    },
    pickupLocationName() {
      return this.transfer?.pickupLocation?.name || null;
    },
    pickupLocationAddress() {
      return getFullAddress(
        this.transfer?.pickupLocation,
        this.$i18n.locale,
        true,
        true,
        false
      );
    },
    dropoffLocationName() {
      return this.transfer?.dropoffLocation?.name || null;
    },
    dropoffLocationAddress() {
      return getFullAddress(
        this.transfer?.dropoffLocation,
        this.$i18n.locale,
        true,
        true,
        false
      );
    },
    pickupDateTime() {
      let dateTimeString = [];
      if (this.transfer?.pickupDate)
        dateTimeString.push(this.transfer.pickupDate.split("T")[0]);
      if (this.transfer?.pickupTime)
        dateTimeString.push(this.transfer.pickupTime);

      if (!dateTimeString.length) return null;

      dateTimeString = dateTimeString.join("T");
      const formatting = {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit"
      };

      return (
        formatDateStringToDate(dateTimeString, this.$i18n.locale, formatting) ||
        null
      );
    },
    passengers() {
      return this.transfer?.passengers || null;
    },
    extras() {
      if (!this.transfer) return [];
      const extras = [];

      if (this.transfer.boosterSeats) {
        extras.push({
          name: this.$t("pages.airportTransferService.wizard.boosterSeatFree"),
          amount: this.transfer.boosterSeats
        });
      }
      if (this.transfer.babyTrolleys) {
        extras.push({
          name: this.$t("pages.airportTransferService.wizard.babyTrolleyFree"),
          amount: this.transfer.babyTrolleys
        });
      }
      if (this.transfer.maxiCosis) {
        extras.push({
          name: this.$t("pages.airportTransferService.wizard.maxiCosiFree"),
          amount: this.transfer.maxiCosis
        });
      }
      if (this.transfer.babySeats) {
        extras.push({
          name: this.$t("pages.airportTransferService.wizard.babySeatFree"),
          amount: this.transfer.babySeats
        });
      }
      if (this.transfer.bicycles) {
        extras.push({
          name: this.$t("pages.airportTransferService.wizard.bicycle"),
          amount: this.transfer.bicycles
        });
      }
      if (this.transfer.golfSets) {
        extras.push({
          name: this.$t("pages.airportTransferService.wizard.golfSet"),
          amount: this.transfer.golfSets
        });
      }

      return extras;
    }
  }
};
</script>
