<template>
  <b-badge :variant="statusColor">
    {{ statusName }}
  </b-badge>
</template>

<script>
import { BBadge } from 'bootstrap-vue'
import { getBookingStatusName, getBookingStatusColor } from '@/utils/methods'

export default {
  components: {
    BBadge,
  },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    statusName() {
      return getBookingStatusName(this.status, this.$i18n.locale)
    },
    statusColor() {
      return getBookingStatusColor(this.status, this.$i18n.locale)
    },
  },
}
</script>
