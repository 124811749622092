<template>
  <b-card
    v-if="booking"
    class="price-details-card"
    :title="$t('Price details')"
  >
    <div>
      <p class="font-weight-bold mb-3">
        {{ $t("Rental price") }}
      </p>
      <div
        v-for="(rate, index) in filteredRates"
        :key="`rate-${index}`"
        class="d-flex justify-content-between mb-2"
      >
        <span>
          {{ formatRateDate(rate.date) }}
        </span>
        <span>
          {{ formatCurrency(rate.price) }}
        </span>
      </div>
      <div
        v-if="showFilterRatesButton"
        class="text-primary cursor-pointer"
        :class="{ 'show-more-rates': filterRates }"
        @click="filterRates = !filterRates"
      >
        {{ filterRates ? $t("Show more") : $t("Show less") }}
      </div>
    </div>

    <div class="divider" />

    <div class="d-flex justify-content-between font-weight-bold mb-3">
      <span class="pr-3">
        {{ $t("Accommodation") }}
      </span>
      <span class="text-right">
        {{ formatCurrency(accommodationPrice) }}
      </span>
    </div>

    <template v-if="discount">
      <div class="d-flex justify-content-between my-50">
        <span class="pr-3">
          {{ $t("Discount") }}
        </span>
        <span class="text-right">
          {{ formatCurrency(-discountPrice) }}
        </span>
      </div>

      <div class="divider" />

      <div class="d-flex justify-content-between font-weight-bold mb-3">
        <span class="pr-3">
          {{ $t("Subtotal") }}
        </span>
        <span class="text-right">
          {{ formatCurrency(subtotal) }}
        </span>
      </div>
    </template>

    <div class="divider" />

    <div
      v-for="(bookingService, index) in chargableServices"
      :key="`chargable-service-${index}`"
      class="d-flex justify-content-between mb-2"
    >
      <span class="pr-3">
        {{ getBookingServiceName(bookingService) }}
      </span>
      <span class="text-right">
        {{ getBookingServicePriceText(bookingService) }}
      </span>
    </div>

    <div
      v-for="(bookingService, index) in unchargableServices"
      :key="`unchargable-service-${index}`"
      class="d-flex justify-content-between mb-2"
    >
      <span class="pr-3">
        {{ getServiceNameWithClientUnitPrice(bookingService) }}
      </span>
      <span v-if="hasProviderPayment(bookingService)" class="text-right">
        {{ $t("pages.booking.toPayToProvider") }}
      </span>
      <span v-else class="text-right">
        {{ $t("pages.booking.toPayOnSite") }}
      </span>
    </div>

    <div class="divider" />

    <div class="d-flex justify-content-between font-weight-bold mb-3">
      <span class="pr-3">
        {{ $t("Total") }}
      </span>
      <span class="text-right">
        {{ formatCurrency(total) }}
      </span>
    </div>

    <div class="bg-light mt-4 p-3">
      <div
        class="d-flex justify-content-between font-weight-bold"
        :class="{ 'mb-2': refunded > 0 || (pending > 0 && !booking.cancelled) }"
      >
        <span class="pr-3">
          {{ $t("Paid") }}
        </span>
        <span class="text-right text-success">
          {{ formatCurrency(paid) }}
        </span>
      </div>
      <div
        v-if="refunded > 0"
        class="d-flex justify-content-between font-weight-bold"
        :class="{ 'mb-2': pending > 0 && !booking.cancelled }"
      >
        <span class="pr-3">
          {{ $t("Refunded") }}
        </span>
        <span class="text-right text-success">
          {{ formatCurrency(refunded) }}
        </span>
      </div>
      <div
        v-if="pending > 0 && !booking.cancelled"
        class="d-flex justify-content-between font-weight-bold"
      >
        <span>
          {{ $t("Remaining") }}
        </span>
        <span class="text-right text-danger">
          {{ formatCurrency(pending) }}
        </span>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import {
  getBookingServiceClientTotalPriceText,
  getBookingServiceName,
  getBookingServiceNameWithClientUnitPrice
} from "@/utils/methods";
import { formatCurrency, formatDateStringToDate } from "@/utils/formatters";

export default {
  components: {
    BCard
  },
  data() {
    return {
      filterRates: true,
      FILTERED_RATES_COUNT: 7
    };
  },
  computed: {
    showFilterRatesButton() {
      return this.rates.length > this.FILTERED_RATES_COUNT;
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    discount() {
      return this.$store.getters["booking/discount"];
    },
    rates() {
      return this.$store.getters["booking/rates"];
    },
    filteredRates() {
      if (!this.rates.length) return [];
      return this.rates.slice(
        0,
        this.filterRates ? this.FILTERED_RATES_COUNT : this.rates.length
      );
    },
    accommodationPrice() {
      return this.$store.getters["booking/subtotal"];
    },
    discountPrice() {
      return this.$store.getters["booking/discountPrice"];
    },
    subtotal() {
      return this.$store.getters["booking/subtotalDiscounted"];
    },
    total() {
      return this.$store.getters["booking/total"];
    },
    paid() {
      return this.$store.getters["booking/paid"];
    },
    refunded() {
      return this.$store.getters["booking/refunded"];
    },
    pending() {
      return this.$store.getters["booking/pending"];
    },
    chargableServices() {
      return this.$store.getters["booking/chargableServices"];
    },
    unchargableServices() {
      return this.$store.getters["booking/unchargableServices"];
    }
  },
  methods: {
    formatRateDate(date) {
      const formatting = { day: "numeric", month: "long", year: "numeric" };
      return (
        formatDateStringToDate(date, this.$i18n.locale, formatting) || null
      );
    },
    formatCurrency(price) {
      return formatCurrency(price);
    },
    getBookingServiceName(bookingService) {
      return getBookingServiceName(bookingService, this.$i18n.locale);
    },
    getBookingServicePriceText(bookingService) {
      return getBookingServiceClientTotalPriceText(
        bookingService,
        this.booking.nights,
        this.$i18n.locale
      );
    },
    getServiceNameWithClientUnitPrice(bookingService) {
      return getBookingServiceNameWithClientUnitPrice(
        bookingService,
        this.$i18n.locale
      );
    },
    hasProviderPayment(bookingService) {
      if (!bookingService.paymentTime) return false;
      return bookingService.paymentTime === "PROVIDER";
    }
  }
};
</script>
