<template>
  <b-overlay :show="loading" variant="white" :opacity="0.9">
    <!-- <b-card
      id="hired-service-card"
      v-b-toggle.service-sidebar
      no-body
      @click="onServiceSelected"
    > -->
    <b-card id="hired-service-card" no-body @click="onServiceSelected">
      <div class="d-flex align-items-center justify-content-between">
        <div>
          <div class="d-flex align-items-center">
            <b-img
              v-if="content.img"
              :src="content.img"
              :alt="$t('Accommodation picture')"
              class="card-img mr-3"
            />
            <div>
              <h5 v-if="content.title" class="mb-0">
                {{ content.title }}
              </h5>
              <div v-if="serviceDate" class="font-weight-bold font-small-3">
                {{ serviceDate }}
              </div>
              <div>
                <small>{{ servicePrice }}</small>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="mr-4">
          <feather-icon icon="ChevronRightIcon" />
        </div> -->
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BImg,
  // BButton,
  VBToggle
} from "bootstrap-vue";
import axios from "@/axios";
import { formatCurrency, formatDateStringToDate } from "@/utils/formatters";

export default {
  components: {
    BOverlay,
    BCard,
    BImg
    // BButton,
  },
  directives: {
    "b-toggle": VBToggle
  },
  props: {
    bookingService: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      details: null
    };
  },
  computed: {
    serviceType() {
      return this.bookingService.service?.code || null;
    },
    content() {
      // TODO: keep adding services as we create the new pages
      switch (this.serviceType) {
        case "AIRPORT_TRANSFER":
          return {
            title: this.$t("pages.booking.services.airportTransfer.title"),
            // eslint-disable-next-line global-require
            img: require("@/assets/foravila/images/services/transfer-service.jpg")
          };
        case "CAR_RENTAL":
          return {
            title: this.$t("pages.booking.services.carRental.title"),
            // eslint-disable-next-line global-require
            img: require("@/assets/foravila/images/services/rental-car-service.png")
          };
        case "CLEANING":
          return {
            title: this.$t("pages.booking.services.cleaning.title"),
            // eslint-disable-next-line global-require
            img: require("@/assets/foravila/images/services/cleaning-service.jpg")
          };
        case "CHARTER":
        default:
          return {
            title: null,
            // eslint-disable-next-line global-require
            img: require("@/assets/foravila/images/services/general-service.jpg")
          };
      }
    },
    serviceDate() {
      if (!this.details) return null;
      // TODO: keep adding services as we create the new pages
      switch (this.serviceType) {
        case "AIRPORT_TRANSFER":
          return this.transferServiceDateTime;
        case "CHARTER":
        case "CAR_RENTAL":
        case "CLEANING":
        default:
          return null;
      }
    },
    transferServiceDateTime() {
      let dateTimeString = [];
      if (this.details?.pickupDate)
        dateTimeString.push(this.details.pickupDate.split("T")[0]);
      if (this.details?.pickupTime)
        dateTimeString.push(this.details.pickupTime);

      if (!dateTimeString.length) return null;

      dateTimeString = dateTimeString.join("T");
      const formatting = {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit"
      };

      return (
        formatDateStringToDate(dateTimeString, this.$i18n.locale, formatting) ||
        null
      );
    },
    servicePrice() {
      return formatCurrency(this.bookingService.pvpPrice);
    }
  },
  created() {
    this.fetchDetails();
  },
  methods: {
    onServiceSelected() {
      if (!this.details) {
        return;
      }

      this.$emit("selected", {
        ...this.bookingService,
        details: this.details
      });
    },
    fetchDetails() {
      // TODO: keep adding services as we create the new pages
      switch (this.serviceType) {
        case "AIRPORT_TRANSFER":
          this.fetchTransferDetails();
          break;
        case "CHARTER":
        case "CAR_RENTAL":
        case "CLEANING":
        default:
          this.details = null;
          break;
      }
    },
    fetchTransferDetails() {
      if (!this.bookingService?.details) return;
      this.loading = true;
      axios
        .get(this.bookingService.details)
        .then(response => {
          this.details = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss">
#hired-service-card {
  // &:hover {
  //   -webkit-transform: translateY(-2px);
  //   transform: translateY(-2px);
  //   -webkit-box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
  //   box-shadow: 0 4px 25px 0 rgb(34 41 47 / 25%);
  // }
  .card-img {
    width: 120px;
    height: auto;
  }
}
</style>
