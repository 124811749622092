<template>
  <b-card :title="$t('Payments')">
    <template v-if="clientPayments.length">
      <b-row>
        <b-col>
          <h5 class="mb-3">
            <u>
              {{ $t("Settled payments") }}
            </u>
          </h5>
        </b-col>
      </b-row>
      <b-row
        v-for="(payment, index) in clientPayments"
        :key="`client-payment-${index}`"
        class="d-flex align-items-center"
        :class="{ 'mb-3': index < clientPayments.length - 1 }"
      >
        <b-col cols="12" md="6">
          <div class="font-weight-bolder mb-1 mb-md-0">
            <b-row>
              <b-col cols="12">
                {{ formatCurrency(payment.pvpAmount) }}
              </b-col>
            </b-row>
            <b-row>
              <b-col class="small" cols="12">
                {{ getPaymentScopeName(payment.scope) }} ·
                {{ formatPaymentDate(payment.date) }}
              </b-col>
            </b-row>
            <b-row v-if="payment.localizator">
              <b-col cols="12" class="small">
                ID: {{ payment.localizator }}
              </b-col>
            </b-row>
          </div>
        </b-col>

        <b-col class="text-left text-md-right" cols="12" md="6">
          <b-badge pill variant="success">
            {{ $t("Paid") }}
          </b-badge>
        </b-col>
      </b-row>

      <div
        v-if="
          clientRefunds.length || showPendingPayments || showUpcomingPayments
        "
        class="divider my-4"
      />
    </template>

    <template v-if="clientRefunds.length">
      <b-row>
        <b-col>
          <h5 class="mb-3">
            <u>
              {{ $t("Refunded payments") }}
            </u>
          </h5>
        </b-col>
      </b-row>
      <b-row
        v-for="(payment, index) in clientRefunds"
        :key="`client-refund-${index}`"
        class="d-flex align-items-center"
        :class="{ 'mb-3': index < clientRefunds.length - 1 }"
      >
        <b-col cols="12" md="6">
          <div class="font-weight-bolder mb-1 mb-md-0">
            <b-row>
              <b-col cols="12">
                {{ formatCurrency(-payment.pvpAmount) }}
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" class="small">
                {{ getPaymentScopeName(payment.scope) }} ·
                {{ formatPaymentDate(payment.date) }}
              </b-col>
            </b-row>
            <b-row v-if="payment.localizator">
              <b-col cols="12" class="small">
                ID: {{ payment.localizator }}
              </b-col>
            </b-row>
          </div>
        </b-col>

        <b-col class="text-left text-md-right" cols="12" md="6">
          <b-badge pill variant="success">
            {{ $t("Refunded") }}
          </b-badge>
        </b-col>
      </b-row>

      <div
        v-if="showPendingPayments || showUpcomingPayments"
        class="divider my-4"
      />
    </template>

    <template v-if="showPendingPayments">
      <b-row>
        <b-col>
          <h5 class="mb-3">
            <u>
              {{ $t("Pending payments") }}
            </u>
          </h5>
        </b-col>
      </b-row>

      <b-row class="d-flex align-items-center">
        <b-col cols="12" md="6">
          <div class="font-weight-bolder mb-1 mb-md-0">
            {{ formatCurrency(pending) }}
            <span class="small">· {{ finalPaymentDueDateText }}</span>
          </div>
        </b-col>
        <b-col class="text-left text-md-right" cols="12" md="6">
          <b-badge pill variant="danger">
            {{ $t("Pending") }}
          </b-badge>
        </b-col>
      </b-row>

      <b-row v-if="booking.active" class="mt-3 d-flex justify-content-end">
        <b-col cols="12" md="auto">
          <b-button
            class="mt-4"
            variant="primary"
            block
            target="blank"
            :href="paymentLink"
          >
            <feather-icon icon="CreditCardIcon" class="mr-1" />
            <span>
              {{ $t("Pay now") }}
            </span>
          </b-button>
        </b-col>
      </b-row>

      <div v-if="showUpcomingPayments" class="divider my-4" />
    </template>

    <template v-if="showUpcomingPayments">
      <b-row>
        <b-col>
          <h5 class="mb-3">
            <u>
              {{ $t("Upcoming payments") }}
            </u>
          </h5>
        </b-col>
      </b-row>

      <b-row
        v-if="pending > 0 && today < finalPaymentDueDate"
        class="d-flex align-items-center mb-3"
      >
        <b-col cols="12" md="6">
          <div class="font-weight-bolder">
            {{ $t("Final payment") }}
          </div>
          <div>
            <small>
              {{ formatCurrency(pending) }}
              <span v-if="finalPaymentDueDate">
                ·
                {{ finalPaymentDueDateText }}
              </span>
            </small>
          </div>
        </b-col>
        <b-col class="text-left text-md-right mt-1 mt-md-0" cols="12" md="6">
          <b-badge pill variant="info">
            {{ $t("Scheduled") }}
          </b-badge>
        </b-col>
      </b-row>

      <b-row
        v-if="unchargableServices.length"
        class="d-flex align-items-center"
      >
        <b-col cols="12" md="6">
          <div class="font-weight-bolder">
            {{ $t("On site payments") }}
          </div>
          <div
            v-for="(unchargableService, index) in unchargableServices"
            :key="`unchargable-service-${index}`"
          >
            <small>{{ getUnchargableServiceText(unchargableService) }}</small>
          </div>
        </b-col>
        <b-col class="text-left text-md-right mt-1 mt-md-0" cols="12" md="6">
          <b-badge pill variant="info">
            {{ $t("Scheduled") }}
          </b-badge>
        </b-col>
      </b-row>
    </template>
  </b-card>
</template>

<script>
import { BCard, BButton, BRow, BCol, BBadge } from "bootstrap-vue";
import {
  formatCurrency,
  formatDateStringToDate,
  formatDateObjectToDate,
  formatDateStringToDatabaseDate
} from "@/utils/formatters";
import {
  getBookingServiceClientUnitPriceText,
  getBookingServiceName,
  getClientPaymentScopeName,
  getTpvLink
} from "@/utils/methods";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BBadge,
    BCard
  },
  computed: {
    booking() {
      return this.$store.getters["booking/booking"];
    },
    clientPayments() {
      return this.$store.getters["booking/clientPayments"];
    },
    clientRefunds() {
      return this.$store.getters["booking/clientRefunds"];
    },
    pending() {
      return this.$store.getters["booking/pending"];
    },
    today() {
      const todayDate = new Date();
      todayDate.setHours(0, 0, 0, 0);
      return todayDate;
    },
    finalPaymentDueDate() {
      return this.$store.getters["booking/finalPaymentDueDate"];
    },
    finalPaymentDueDateText() {
      const formatting = { month: "long", day: "2-digit", year: "numeric" };
      return formatDateObjectToDate(
        this.finalPaymentDueDate,
        this.$i18n.locale,
        formatting
      );
    },
    unchargableServices() {
      return this.$store.getters["booking/unchargableServices"];
    },
    paymentLink() {
      if (!this.booking) {
        return null;
      }

      const amount = (this.pending / 100).toFixed(2);
      const comment = `${this.$t("Pending payment", this.locale)} ${
        this.booking.localizator
      }`;
      const accommodation = this.booking.accommodation?.name || null;
      const localizator = this.booking.localizator || null;
      const checkin = formatDateStringToDatabaseDate(this.booking.checkin);
      const checkout = formatDateStringToDatabaseDate(this.booking.checkout);

      return getTpvLink({
        locale: this.$i18n.locale,
        amount,
        comment,
        accommodation,
        localizator,
        checkin,
        checkout
      });
    },
    showPendingPayments() {
      // TODO: millorar comparativa de dates
      return this.pending > 0 && this.today >= this.finalPaymentDueDate;
    },
    showUpcomingPayments() {
      return (
        this.unchargableServices.length ||
        (this.pending > 0 && this.today < this.finalPaymentDueDate)
      );
    }
  },
  methods: {
    formatCurrency(price) {
      return formatCurrency(price);
    },
    formatPaymentDate(date) {
      const formatting = { day: "2-digit", month: "long", year: "numeric" };
      return formatDateStringToDate(date, this.$i18n.locale, formatting);
    },
    getBookingServiceName(bookingService) {
      return getBookingServiceName(bookingService, this.$i18n.locale);
    },
    getBookingServicePrice(bookingService) {
      return getBookingServiceClientUnitPriceText(
        bookingService,
        this.$i18n.locale
      );
    },
    getUnchargableServiceText(bookingService) {
      if (!bookingService) return null;
      const text = [];

      const serviceName = this.getBookingServiceName(bookingService);
      if (serviceName) text.push(serviceName);

      const servicePrice = this.getBookingServicePrice(bookingService);
      if (servicePrice) text.push(servicePrice);

      return text.length ? text.join(": ") : null;
    },
    getPaymentScopeName(code) {
      return getClientPaymentScopeName(code, this.$i18n.locale);
    }
  }
};
</script>
