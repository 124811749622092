<template>
  <div>
    <h4>{{ $t('pages.booking.schedulesAndTransport') }}</h4>
    <h5 class="mt-4">
      {{ $t('Arrival') }}
    </h5>
    <b-row>
      <b-col
        cols="12"
        sm="4"
        class="mb-2"
      >
        <div class="font-weight-bold">
          {{ $t('pages.booking.accommodationArrivalTime') }}
        </div>
        <div class="font-weight-light">
          {{ accommodationArrivalTime }}
        </div>
      </b-col>
      <b-col
        cols="12"
        sm="4"
        class="mb-2"
      >
        <div class="font-weight-bold">
          {{ $t('pages.booking.modeOfTransport') }}
        </div>
        <div class="font-weight-light">
          {{ arrivalTransport }}
        </div>
      </b-col>
      <b-col
        v-if="arrivalTransportLocalizator"
        cols="12"
        sm="4"
        class="mb-2"
      >
        <div class="font-weight-bold">
          {{ $t('Flight number') }}
        </div>
        <div class="font-weight-light">
          {{ arrivalTransportLocalizator }}
        </div>
      </b-col>
      <b-col
        v-if="transportArrivalTime"
        cols="12"
        sm="4"
        class="mb-2"
      >
        <div class="font-weight-bold">
          {{ $t('Arrival time') }}
        </div>
        <div class="font-weight-light">
          {{ transportArrivalTime }}
        </div>
      </b-col>
    </b-row>

    <h5 class="mt-2">
      {{ $t('Departure') }}
    </h5>
    <b-row>
      <b-col
        cols="12"
        sm="4"
        class="mb-2"
      >
        <div class="font-weight-bold">
          {{ $t('pages.booking.accommodationDepartureTime') }}
        </div>
        <div class="font-weight-light">
          {{ accommodationDepartureTime }}
        </div>
      </b-col>
      <b-col
        cols="12"
        sm="4"
        class="mb-2"
      >
        <div class="font-weight-bold">
          {{ $t('pages.booking.modeOfTransport') }}
        </div>
        <div class="font-weight-light">
          {{ departureTransport }}
        </div>
      </b-col>
      <b-col
        v-if="departureTransportLocalizator"
        cols="12"
        sm="4"
        class="mb-2"
      >
        <div class="font-weight-bold">
          {{ $t('Flight number') }}
        </div>
        <div class="font-weight-light">
          {{ departureTransportLocalizator }}
        </div>
      </b-col>
      <b-col
        v-if="transportDepartureTime"
        cols="12"
        sm="4"
        class="mb-2"
      >
        <div class="font-weight-bold">
          {{ $t('Departure time') }}
        </div>
        <div class="font-weight-light">
          {{ transportDepartureTime }}
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col class="d-flex justify-content-end">
        <b-button
          v-b-modal.edit-checkin-modal
          variant="primary"
        >
          {{ $t('Edit') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, VBModal,
} from 'bootstrap-vue'
import { getTransportName } from '@/utils/methods'

export default {
  components: {
    BButton,
    BRow,
    BCol,
  },
  directives: {
    'b-modal': VBModal,
  },
  computed: {
    details() {
      return this.$store.getters['booking/details']
    },
    accommodationArrivalTime() {
      return this.details?.accommodationArrivalTime || this.$t('Not defined')
    },
    arrivalTransport() {
      return getTransportName(this.details?.arrivalTransport, this.$i18n.locale) || this.$t('Not defined')
    },
    arrivalTransportLocalizator() {
      return this.details?.arrivalTransportLocalizator || null
    },
    transportArrivalTime() {
      return this.details?.transportArrivalTime || null
    },
    accommodationDepartureTime() {
      return this.details?.accommodationDepartureTime || this.$t('Not defined')
    },
    departureTransport() {
      return getTransportName(this.details?.departureTransport, this.$i18n.locale) || this.$t('Not defined')
    },
    departureTransportLocalizator() {
      return this.details?.departureTransportLocalizator || null
    },
    transportDepartureTime() {
      return this.details?.transportDepartureTime || null
    },
  },
}
</script>
