var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.loading,"variant":"white","opacity":0.9}},[_c('validation-observer',{ref:"guestFormRules"},[_c('b-form',{attrs:{"id":"guest-form"}},[_c('b-row',{staticClass:"mb-3"},[_c('b-col',{attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"guest-gender"}},[_vm._v(" "+_vm._s(_vm.$t("Gender"))+" ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('Gender'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"fr-radio-button w-100",class:{ 'is-invalid': errors.length > 0 },attrs:{"id":"guest-gender","options":_vm.genderOptions,"text-field":"label","buttons":"","button-variant":"outline-secondary"},model:{value:(_vm.newGuest.gender),callback:function ($$v) {_vm.$set(_vm.newGuest, "gender", $$v)},expression:"newGuest.gender"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('First name'),"label-for":"guest-first-name"}},[_c('validation-provider',{attrs:{"name":_vm.$t('First name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"guest-first-name","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.newGuest.firstName),callback:function ($$v) {_vm.$set(_vm.newGuest, "firstName", $$v)},expression:"newGuest.firstName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Last name'),"label-for":"guest-last-name"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Last name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"guest-last-name","state":errors.length > 0 ? false : null,"trim":""},model:{value:(_vm.newGuest.lastName),callback:function ($$v) {_vm.$set(_vm.newGuest, "lastName", $$v)},expression:"newGuest.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"guest-birthday"}},[_vm._v(" "+_vm._s(_vm.$t("Birthdate"))+" ")]),_c('validation-provider',{attrs:{"name":"birthdate","rules":"required|pastdate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-datepicker',{attrs:{"max":new Date(),"state":errors.length > 0 ? false : null},model:{value:(_vm.newGuest.birthDate),callback:function ($$v) {_vm.$set(_vm.newGuest, "birthDate", $$v)},expression:"newGuest.birthDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{staticClass:"mt-3",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Nationality'),"label-for":"guest-country"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Nationality'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"w-full",class:{ 'is-invalid': errors.length > 0 },attrs:{"id":"guest-country","options":_vm.countryOptions,"reduce":function (option) { return option.value; }},model:{value:(_vm.newGuest.nationality),callback:function ($$v) {_vm.$set(_vm.newGuest, "nationality", $$v)},expression:"newGuest.nationality"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"guest-id-type"}},[_vm._v(" "+_vm._s(_vm.$t("ID type"))+" ")]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('ID type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{staticClass:"fr-radio-button w-100",class:{ 'is-invalid': errors.length > 0 },attrs:{"id":"guest-id-type","options":_vm.idTypeOptions,"text-field":"label","buttons":"","button-variant":"outline-secondary"},model:{value:(_vm.newGuest.idType),callback:function ($$v) {_vm.$set(_vm.newGuest, "idType", $$v)},expression:"newGuest.idType"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":_vm.$t('ID number'),"label-for":"guest-id-number"}},[_c('validation-provider',{attrs:{"name":_vm.$t('ID number'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"guest-id-number","trim":"","formatter":function (value) { return value.toUpperCase(); },"state":errors.length > 0 ? false : null},model:{value:(_vm.newGuest.idNumber),callback:function ($$v) {_vm.$set(_vm.newGuest, "idNumber", $$v)},expression:"newGuest.idNumber"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"guest-id-expiry"}},[_vm._v(" "+_vm._s(_vm.$t("ID issue date"))+" ")]),_c('validation-provider',{attrs:{"name":"idIssueDate","rules":"required|pastdate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-datepicker',{attrs:{"max":new Date(),"state":errors.length > 0 ? false : null},model:{value:(_vm.newGuest.idIssueDate),callback:function ($$v) {_vm.$set(_vm.newGuest, "idIssueDate", $$v)},expression:"newGuest.idIssueDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"mb-4"},[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('label',{attrs:{"for":"guest-signature"}},[_vm._v(" "+_vm._s(_vm.$t("Signature"))+" "),_c('small',{staticClass:"cursor-pointer text-primary ml-25",on:{"click":function($event){return _vm.clearSignature()}}},[_vm._v(" "+_vm._s(_vm.$t("pages.booking.deleteSignature"))+" ")])])]),(_vm.guest && _vm.newGuest.signature)?_c('b-col',{attrs:{"cols":"12"}},[_c('b-img',{staticClass:"guest-signature mb-1",attrs:{"id":"guest-signature","src":_vm.newGuest.signature,"alt":"","fluid":"","center":""}})],1):_c('b-col',{attrs:{"cols":"12"}},[_c('vue-signature-pad',{ref:"signaturePad",staticClass:"mb-1 cursor-pointer",class:{ 'is-invalid': _vm.signaturePadError },attrs:{"id":"signature-pad","width":"100%","height":"250px","options":_vm.signaturePadOptions}})],1)],1),_c('b-row',{staticClass:"mb-1 d-flex justify-content-between justify-content-sm-end"},[_c('b-col',{attrs:{"cols":"6","sm":"auto"}},[_c('b-button',{attrs:{"block":"","variant":"light"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" "+_vm._s(_vm.$t("Cancel"))+" ")])],1),_c('b-col',{attrs:{"cols":"6","sm":"auto"}},[_c('b-button',{attrs:{"block":"","variant":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" "+_vm._s(_vm.$t("Save"))+" ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }