<template>
  <div
    class="service-item font-weight-bold mx-1 mb-4 bg-light p-4 border"
    :class="[borderClass, {'cursor-pointer': !hired}]"
    @click.stop="selected = !selected"
  >
    <b-row class="mb-3 d-flex justify-content-between">
      <b-col
        class="text-left"
      >
        <b-form-checkbox
          v-if="!hired"
          v-model="selected"
          class="custom-control-primary avoid-clicks"
        />
        <feather-icon
          v-else
          icon="CheckIcon"
          class="text-success"
          size="22"
        />
      </b-col>
      <b-col
        class="text-right"
      >
        <div :class="{'no-select': !hired}">
          <b-badge
            v-if="hired"
            variant="success"
          >
            {{ $t('Hired') }}
          </b-badge>
          <b-badge
            v-else
            variant="light-danger"
          >
            {{ $t('Not hired') }}
          </b-badge>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
      >
        <div :class="{'no-select': !hired}">
          <div v-if="serviceName">
            {{ serviceName }}
          </div>
          <div
            v-if="price"
            class="font-small-3 font-weight-normal"
          >
            {{ price }}
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BBadge, BFormCheckbox,
} from 'bootstrap-vue'
import { getServiceName } from '@/utils/methods'

export default {
  components: {
    BRow,
    BCol,
    BBadge,
    BFormCheckbox,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
    price: {
      type: String,
      default: null,
    },
    paymentTime: {
      type: String,
      default: null,
    },
    hired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: false,
    }
  },
  computed: {
    borderClass() {
      if (this.hired) return 'border-success'
      if (this.selected) return 'border-primary'
      return null
    },
    serviceName() {
      return getServiceName(this.service.service.code, this.$i18n.locale) || null
    },
  },
  watch: {
    selected(value) {
      this.$emit('selected', {
        service: this.service,
        selected: value,
      })
    },
  },
}
</script>

<style lang="scss">
  .service-item {
    min-height: 112px;
  }
</style>
