<template>
  <b-overlay :show="loading" variant="white" :opacity="0.9" rounded="false">
    <h4>{{ $t("Guests") }}</h4>
    <b-alert variant="dark" :show="showAddClientAsGuestAlert" class="my-3">
      <div class="alert-body">
        <feather-icon
          icon="HelpCircleIcon"
          size="25"
          class="mr-1"
        ></feather-icon>
        {{ $t("pages.booking.addClientAsGuest") }}
        <b-button
          class="text-secondary ml-2 mr-3"
          size="sm"
          @click="onAddClientAsGuestButtonClicked"
        >
          {{ $t("Yes") }}
        </b-button>
        <b-button
          size="sm"
          class="text-secondary"
          @click="onDiscardAddClientAsGuestButtonClicked"
        >
          No
        </b-button>
      </div>
    </b-alert>

    <b-alert :show="capacityCompleted" class="my-3" variant="warning">
      <div class="alert-body">
        {{ $t("pages.booking.capacityCompleted") }}
      </div>
    </b-alert>

    <guests-list
      :guests="guests"
      :hide-button="capacityCompleted"
      class="mb-2"
      @add-guest="onAddGuest"
      @edit-guest="onEditGuest"
      @delete-guest="onDeleteGuest"
    />

    <small v-if="invalidGuestsNumber" class="text-danger">
      {{ $t("pages.booking.checkin.atLeast1Guest") }}
    </small>

    <b-form>
      <b-row class="mt-4">
        <b-col cols="12" sm="6" class="mb-4 mb-sm-0">
          <div class="d-flex align-items-center justify-content-between">
            <label for="children-spinbutton">
              {{ $t("Children") }}
              <div class="text-muted">
                {{ $t("pages.booking.childrenAgeDefinition") }}
              </div>
            </label>
          </div>
          <b-form-spinbutton
            id="children-spinbutton"
            v-model="children"
            :min="0"
            :max="maxChildrenNumber"
            :disabled="childrenInputDisabled"
          />
        </b-col>
        <b-col cols="12" sm="6">
          <div class="d-flex align-items-center justify-content-between">
            <label for="babies-spinbutton">
              {{ $t("Babies") }}
              <div class="text-muted">
                {{ $t("pages.booking.babiesAgeDefinition") }}
              </div>
            </label>
          </div>
          <b-form-spinbutton
            id="babies-spinbutton"
            v-model="babies"
            :min="0"
            max="4"
          />
          <b-alert
            v-if="showBabyRequestsAlert"
            variant="secondary"
            :show="true"
            class="mb-0 mt-4"
          >
            <div class="alert-body">
              <b-row class="mb-2">
                <b-col cols="12" sm="6" class="mb-3 mb-sm-0">
                  <label for="cots-spinbutton">
                    {{ $t("Baby chairs") }}
                  </label>
                  <b-form-spinbutton
                    id="cots-spinbutton"
                    v-model="babyCots"
                    :min="0"
                    :max="babies"
                  />
                </b-col>
                <b-col cols="12" sm="6">
                  <label for="highchairs-spinbutton">
                    {{ $t("Baby cots") }}
                  </label>
                  <b-form-spinbutton
                    id="highchairs-spinbutton"
                    v-model="babyChairs"
                    :min="0"
                    :max="babies"
                  />
                </b-col>
              </b-row>
            </div>
          </b-alert>
        </b-col>
      </b-row>

      <b-row class="mt-4">
        <b-col class="d-flex justify-content-end">
          <b-button variant="primary" @click="onSaveButtonClicked">
            {{ $t("Save") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>

    <b-modal
      id="guest-modal"
      v-model="guestModalVisible"
      :title="guestModalTitle"
      hide-footer
      scrollable
      :no-close-on-esc="loadingGuest"
      :no-close-on-backdrop="loadingGuest"
      :hide-header-close="loadingGuest"
    >
      <guest-form
        :guest="selectedGuest"
        @cancel="onCancelGuestModal"
        @guest-added="onGuestAdded"
        @add-guest-error="onAddGuestError"
        @guest-edited="onGuestEdited"
        @edit-guest-error="onEditGuestError"
        @loading="onGuestLoading"
      />
    </b-modal>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormSpinbutton,
  BAlert,
  BModal
} from "bootstrap-vue";
import { formatDateObjectToDatabaseDateTime } from "@/utils/formatters";
import GuestsList from "@/views/foravila/bookings/booking/components/checkin/GuestsList.vue";
import GuestForm from "@/views/foravila/bookings/booking/components/checkin/GuestForm.vue";
import { getGuestsText, notifyError, scrollToId } from "@/utils/methods";

export default {
  components: {
    BOverlay,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormSpinbutton,
    BAlert,
    GuestsList,
    BModal,
    GuestForm
  },
  data() {
    return {
      loading: false,
      loadingGuest: false,
      discartedAlert: false,
      guestModalTitle: null,
      guestModalVisible: false,
      selectedGuest: null,
      children: 0,
      babies: 0,
      babyCots: 0,
      babyChairs: 0,
      invalidGuestsNumber: false
    };
  },
  computed: {
    accommodation() {
      return this.$store.getters["booking/accommodation"];
    },
    booking() {
      return this.$store.getters["booking/booking"];
    },
    details() {
      return this.$store.getters["booking/details"];
    },
    stats() {
      return this.$store.getters["booking/stats"];
    },
    guests() {
      return this.$store.getters["booking/guests"];
    },
    client() {
      return this.$store.getters["client/client"];
    },
    clientOnlineCheckinCompleted() {
      return this.$store.getters["booking/clientOnlineCheckinCompleted"];
    },
    guestsOnlineCheckinCompleted() {
      return this.$store.getters["booking/guestsOnlineCheckinCompleted"];
    },
    onlineCheckinCompleted() {
      return this.$store.getters["booking/onlineCheckinCompleted"];
    },
    showAddClientAsGuestAlert() {
      if (
        !this.client ||
        this.discartedAlert ||
        !this.clientOnlineCheckinCompleted ||
        this.guestsOnlineCheckinCompleted ||
        this.capacityCompleted
      )
        return false;

      // Check if the client can be already found in the guests list
      const clientGuest = this.guests.find(
        guest => guest.idNumber === this.client.idNumber
      );
      return !clientGuest;
    },
    showBabyRequestsAlert() {
      return this.babies > 0;
    },
    childrenInputDisabled() {
      if (!this.accommodation?.capacity) return false;
      const guestsLength = this.guests.length || 0;
      const capacity = +this.accommodation.capacity;
      return guestsLength === capacity;
    },
    maxChildrenNumber() {
      if (!this.accommodation || !this.accommodation.capacity) return null;
      const guestsLength = this.guests.length || 1;
      const capacity = +this.accommodation.capacity;
      return capacity - guestsLength;
    },
    capacityCompleted() {
      if (!this.accommodation?.capacity) return null;
      const guestsLength = this.guests.length || 0;
      const capacity = +this.accommodation.capacity;
      return guestsLength + this.children >= capacity;
    }
  },
  watch: {
    babies(babies) {
      if (!babies) {
        this.babyCots = 0;
        this.babyChairs = 0;
      } else {
        // If the number of selected cots/chairs is higher than the number of selected babies,
        // set the new number of selected cots/chairs equal to the number of babies.
        this.babyCots = this.babyCots > babies ? babies : this.babyCots;
        this.babyChairs = this.babyChairs > babies ? babies : this.babyChairs;
      }
    },
    guestModalVisible(visible) {
      if (!visible) this.selectedGuest = null;
    }
  },
  methods: {
    onAddClientAsGuestButtonClicked() {
      this.guestModalTitle = this.$t("pages.booking.addNewAdult");
      this.selectedGuest = {
        gender: this.client.gender,
        firstName: this.client.firstName,
        lastName: this.client.lastName,
        birthDate: this.client.birthDate,
        nationality: this.client.nationality,
        idType: this.client.idType,
        idNumber: this.client.idNumber
      };
      this.$bvModal.show("guest-modal");
    },
    onGuestLoading(loading) {
      this.loadingGuest = loading;
    },
    onEditGuest(guest) {
      this.selectedGuest = guest;
      this.guestModalTitle = this.$t("pages.booking.editGuest");
      this.$bvModal.show("guest-modal");
    },
    onDeleteGuest(guest) {
      this.selectedGuest = guest;
      this.$swal({
        text: this.$t("pages.booking.deleteGuestPrompt", {
          name: guest.firstName
        }),
        icon: "error",
        showCancelButton: true,
        confirmButtonText: this.$t("Delete"),
        cancelButtonText: this.$t("Cancel"),
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: "btn btn-outline-danger ml-4"
        },
        buttonsStyling: false
      }).then(result => {
        if (result.value) {
          this.deleteSelectedGuest();
        }
      });
    },
    deleteSelectedGuest() {
      this.loading = true;
      this.$store
        .dispatch("booking/deleteGuest", this.selectedGuest.uuid)
        .catch(() =>
          notifyError(
            this.$t("errors.deleteGuest.title"),
            this.$t("errors.deleteGuest.text")
          )
        )
        .finally(() => {
          this.loading = false;
        });
    },
    onAddGuest() {
      this.guestModalTitle = this.$t("pages.booking.addNewAdult");
      this.selectedGuest = null;
      this.$bvModal.show("guest-modal");
    },
    onCancelGuestModal() {
      this.$bvModal.hide("guest-modal");
    },
    onGuestAdded() {
      this.$bvModal.hide("guest-modal");
      this.invalidGuestsNumber = false;
    },
    onAddGuestError() {
      this.$bvModal.hide("guest-modal");
    },
    onGuestEdited() {
      this.$bvModal.hide("guest-modal");
    },
    onEditGuestError() {
      this.$bvModal.hide("guest-modal");
    },
    onDiscardAddClientAsGuestButtonClicked() {
      this.discartedAlert = true;
      // TODO: store and collect this value from a cookie
    },
    onSaveButtonClicked() {
      if (this.guests.length) {
        this.$swal({
          text: this.$t("pages.booking.checkin.allGuestsRegisteredPopup", {
            guests: getGuestsText(
              {
                adults: this.guests.length,
                children: this.children,
                babies: this.babies
              },
              this.$i18n.locale
            )
          }),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: this.$t("Yes"),
          cancelButtonText: this.$t("No"),
          customClass: {
            confirmButton: "btn btn-danger",
            cancelButton: "btn btn-outline-danger ml-4"
          },
          buttonsStyling: false
        }).then(result => {
          if (result.value) {
            this.save();
          }
        });
      } else {
        this.invalidGuestsNumber = true;
      }
    },
    save() {
      this.loading = true;

      const promises = [];

      // Update Booking
      promises.push(
        this.$store.dispatch("booking/updateBooking", {
          uuid: this.booking.uuid,
          children: this.children,
          babies: this.babies,
          adults: this.guests.length
        })
      );

      // Update BookingDetails
      promises.push(
        this.$store.dispatch("booking/updateDetails", {
          uuid: this.details.uuid,
          babyChairs: this.babyChairs,
          babyCots: this.babyCots
        })
      );

      // If all promises are OK, update the BookingStats
      Promise.all(promises)
        .then(() => {
          this.$store
            .dispatch("booking/updateStats", {
              uuid: this.stats.uuid,
              guestsOnlineCheckinCompleted: true,
              guestsOnlineCheckinCompletedAt: formatDateObjectToDatabaseDateTime(
                new Date()
              )
            })
            .finally(() => {
              this.loading = false;
              if (this.onlineCheckinCompleted)
                scrollToId("checkin-status-card", 100);
              else scrollToId("guests-card", 100);
            });
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>
