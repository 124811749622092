<template>
  <b-list-group id="guests-list" flush>
    <template v-if="guests.length">
      <b-list-group-item
        v-for="guest in guests"
        :key="`guest-${guest.uuid}`"
        class="d-flex justify-content-between align-items-center"
      >
        <div class="order-2 mb-3 mb-sm-0">
          <template v-if="!readOnly">
            <div class="d-sm-none d-flex flex-nowrap">
              <b-button
                size="sm"
                variant="flat-dark"
                class="btn-icon"
                @click="onEditGuestButtonClicked(guest)"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
              <b-button
                size="sm"
                variant="flat-dark"
                class="btn-icon"
                @click="onDeleteGuestButtonClicked(guest)"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </div>
            <b-dropdown variant="link" no-caret class="d-none d-sm-block">
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body"
                />
              </template>
              <b-dropdown-item @click="onEditGuestButtonClicked(guest)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">
                  {{ $t("Edit") }}
                </span>
              </b-dropdown-item>

              <b-dropdown-item @click="onDeleteGuestButtonClicked(guest)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">
                  {{ $t("Delete") }}
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </div>
        <div class="d-flex flex-grow-1 order-1">
          <b-avatar variant="light-secondary" class="mr-3">
            <feather-icon icon="UserIcon" size="20" />
          </b-avatar>

          <div>
            <h6 class="mb-0 three-dots">
              {{ getGuestName(guest) }}
            </h6>
            <b-card-text class="font-small-2 mb-0 three-dots">
              {{ guestSubtitle(guest) }}
            </b-card-text>
          </div>
        </div>
      </b-list-group-item>
    </template>

    <b-list-group-item
      v-if="showAddGuestButton"
      class="d-sm-flex justify-content-between align-items-center cursor-pointer bg-light"
      @click="onAddGuestButtonClicked"
    >
      <div class="d-flex align-items-center order-1">
        <span class="mr-4">
          <feather-icon icon="PlusIcon" size="20" />
        </span>
        <div>
          {{ $t("pages.booking.addNewAdult") }}
          <div class="text-muted small">
            {{ $t("pages.booking.adultsAgeDefinition") }}
          </div>
        </div>
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import {
  BButton,
  BDropdown,
  BDropdownItem,
  BListGroup,
  BListGroupItem,
  BCardText,
  BAvatar
} from "bootstrap-vue";
import { getGenderName } from "@/utils/methods";

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BListGroup,
    BAvatar,
    BListGroupItem,
    BButton,
    BCardText
  },
  props: {
    guests: {
      type: Array,
      required: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    hideButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    showAddGuestButton() {
      return !this.readOnly && !this.hideButton && !this.capacityCompleted;
    }
  },
  methods: {
    onEditGuestButtonClicked(guest) {
      this.$emit("edit-guest", guest);
    },
    onDeleteGuestButtonClicked(guest) {
      this.$emit("delete-guest", guest);
    },
    onAddGuestButtonClicked() {
      this.$emit("add-guest");
    },
    getGuestName(guest) {
      return guest?.fullName || this.$t("Adult");
    },
    guestSubtitle(guest) {
      if (!guest) return null;

      const text = [];

      if (guest.gender)
        text.push(getGenderName(guest.gender, this.$i18n.locale));
      if (guest.age || guest.age === 0)
        text.push(`${guest.age} ${this.$t("pages.booking.yearsOld")}`);

      return text.length > 0 ? text.join(", ") : null;
    },
    guestAgeWarning(guest) {
      return !guest.age || guest.age <= 14;
    }
  }
};
</script>
