<template>
  <b-overlay :show="loading" variant="white" :opacity="0.9" rounded="false">
    <validation-observer ref="schedulesFormRules">
      <h4>{{ $t("pages.booking.schedulesAndTransport") }}</h4>
      <b-form id="schedules-form">
        <h5 class="mt-4">
          {{ $t("Arrival") }}
        </h5>
        <b-row>
          <b-col md="3">
            <b-form-group
              :label="$t('pages.booking.accommodationArrivalTime')"
              label-for="accommodation-arrival-time"
            >
              <validation-provider
                #default="{ errors }"
                name="accommodationArrivalTime"
                rules="required|checkin"
              >
                <b-form-timepicker
                  id="accommodation-arrival-time"
                  v-model="accommodationArrivalTime"
                  label-no-time-selected=""
                  :locale="timepickerLocale"
                  minutes-step="10"
                  :state="errors.length > 0 ? false : null"
                  hide-header
                  no-close-button
                  class="mb-2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$t('pages.booking.modeOfTransport')"
              label-for="arrival-transport"
            >
              <v-select
                id="arrival-transport"
                v-model="arrivalTransport"
                :options="transportMethodOptions"
                :reduce="option => option.value"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="arrivalTransport === 'PLANE'" md="3">
            <b-form-group
              ref="arrivalTransportLocalizatorFormGroup"
              :label="$t('Flight number')"
              label-for="arrival-transport-localizator"
            >
              <validation-provider
                #default="{ errors }"
                name="arrivalFlightNumber"
                rules="required"
              >
                <b-form-input
                  id="arrival-transport-localizator"
                  v-model="arrivalTransportLocalizator"
                  class="mb-2"
                  trim
                  :formatter="value => value.toUpperCase()"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col v-if="arrivalTransport === 'BOAT'" md="3">
            <b-form-group
              ref="transportArrivalTimeFormGroup"
              :label="$t('Arrival time')"
              label-for="transport-arrival-time"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Arrival time')"
                rules="required"
              >
                <b-form-timepicker
                  id="transport-arrival-time"
                  v-model="transportArrivalTime"
                  label-no-time-selected=""
                  :locale="timepickerLocale"
                  minutes-step="10"
                  hide-header
                  no-close-button
                  class="mb-2"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <h5 class="mt-2">
          {{ $t("Departure") }}
        </h5>
        <b-row>
          <b-col md="3">
            <b-form-group
              :label="$t('pages.booking.accommodationDepartureTime')"
              label-for="accommodation-departure-time"
            >
              <validation-provider
                #default="{ errors }"
                name="accommodationDepartureTime"
                rules="required|checkout"
              >
                <b-form-timepicker
                  id="accommodation-departure-time"
                  v-model="accommodationDepartureTime"
                  label-no-time-selected=""
                  :locale="timepickerLocale"
                  minutes-step="10"
                  :state="errors.length > 0 ? false : null"
                  hide-header
                  no-close-button
                  class="mb-2"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              :label="$t('pages.booking.modeOfTransport')"
              label-for="departure-transport"
            >
              <v-select
                id="departure-transport"
                v-model="departureTransport"
                :options="transportMethodOptions"
                :reduce="option => option.value"
                :clearable="false"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="departureTransport === 'PLANE'" md="3">
            <b-form-group
              ref="departureTransportLocalizatorFormGroup"
              :label="$t('Flight number')"
              label-for="departure-transport-localizator"
            >
              <b-form-input
                id="departure-transport-localizator"
                v-model="departureTransportLocalizator"
                class="mb-2"
                trim
                :formatter="value => value.toUpperCase()"
              />
            </b-form-group>
          </b-col>
          <b-col v-if="departureTransport === 'BOAT'" md="3">
            <b-form-group
              ref="transportDepartureTimeFormGroup"
              :label="$t('Departure time')"
              label-for="transport-departure-time"
            >
              <validation-provider
                #default="{ errors }"
                :name="$t('Departure time')"
                rules="required"
              >
                <b-form-timepicker
                  id="transport-departure-time"
                  v-model="transportDepartureTime"
                  label-no-time-selected=""
                  :locale="timepickerLocale"
                  minutes-step="10"
                  hide-header
                  no-close-button
                  class="mb-2"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col class="d-flex justify-content-end">
            <b-button variant="primary" @click="onSaveButtonClicked">
              {{ $t("Save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormTimepicker
} from "bootstrap-vue";
import vSelect from "vue-select";
import { transportMethodOptions } from "@/utils/select-options";
import { formatDateObjectToDatabaseDateTime } from "@/utils/formatters";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, checkin, checkout } from "@foravila-validations";
import { scrollToId } from "@/utils/methods";

export default {
  components: {
    BOverlay,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    vSelect,
    BButton,
    BFormInput,
    BFormTimepicker,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      loading: false,
      accommodationArrivalTime: "16:00:00",
      arrivalTransport: "PLANE",
      arrivalTransportLocalizator: null,
      transportArrivalTime: null,
      accommodationDepartureTime: "10:00:00",
      departureTransport: "PLANE",
      departureTransportLocalizator: null,
      transportDepartureTime: null,
      timepickerFormat: "HH:mm:ss",
      // Validation rules
      required,
      checkin,
      checkout
    };
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale.substring(0, 2).toLowerCase();
    },
    client() {
      return this.$store.getters["client/client"];
    },
    details() {
      return this.$store.getters["booking/details"];
    },
    stats() {
      return this.$store.getters["booking/stats"];
    },
    timepickerLocale() {
      if (!this.client) return "en";
      if (this.client.nationality === "ES") return "es";
      return "en";
    },
    transportMethodOptions() {
      return transportMethodOptions(this.$i18n.locale);
    },
    isValidCheckinTime() {
      if (this.accommodationArrivalTime == null) return null;
      const minTime = this.$moment("16:00:00", this.timepickerFormat);
      const maxTime = this.$moment("02:00:00", this.timepickerFormat);
      return !this.$moment(
        this.accommodationArrivalTime,
        this.timepickerFormat
      ).isBetween(maxTime, minTime, null, "()");
    },
    isValidCheckoutTime() {
      if (this.accommodationDepartureTime == null) return null;
      const minTime = this.$moment("00:00:00", this.timepickerFormat);
      const maxTime = this.$moment("10:00:00", this.timepickerFormat);
      return this.$moment(
        this.accommodationDepartureTime,
        this.timepickerFormat
      ).isBetween(minTime, maxTime, null, "[]");
    },
    clientOnlineCheckinCompleted() {
      return this.$store.getters["booking/clientOnlineCheckinCompleted"];
    },
    guestsOnlineCheckinCompleted() {
      return this.$store.getters["booking/guestsOnlineCheckinCompleted"];
    },
    arrivalOnlineCheckinCompleted() {
      return this.$store.getters["booking/arrivalOnlineCheckinCompleted"];
    },
    onlineCheckinCompleted() {
      return this.$store.getters["booking/onlineCheckinCompleted"];
    }
  },
  watch: {
    arrivalTransport(transport) {
      if (!transport || transport === "NONE") {
        this.arrivalTransportLocalizator = null;
        this.transportArrivalTime = null;
      }
      // ! Working but improvement needed
      // TODO: we use nextTick to allow vee-validate to update the validation messages
      this.$nextTick(() => {
        this.switchValidationLocale();
      });
    },
    departureTransport(transport) {
      if (!transport || transport === "NONE") {
        this.departureTransportLocalizator = null;
        this.transportDepartureTime = null;
      }
      // ! Working but improvement needed
      // TODO: we use nextTick to allow vee-validate to update the validation messages
      this.$nextTick(() => {
        this.switchValidationLocale();
      });
    },
    currentLanguage() {
      this.switchValidationLocale();
    }
  },
  mounted() {
    this.switchValidationLocale();
  },
  methods: {
    switchValidationLocale() {
      localize(this.currentLanguage);
      this.$refs.schedulesFormRules.validate();
    },
    onSaveButtonClicked() {
      this.$refs.schedulesFormRules.validate().then(success => {
        if (success) {
          this.loading = true;

          this.$store
            .dispatch("booking/updateDetails", {
              uuid: this.details.uuid,
              arrivalTransport: this.arrivalTransport,
              arrivalTransportLocalizator: this.arrivalTransportLocalizator,
              departureTransport: this.departureTransport,
              departureTransportLocalizator: this.departureTransportLocalizator,
              transportArrivalTime: this.transportArrivalTime
                ? this.transportArrivalTime.slice(0, -3)
                : null,
              transportDepartureTime: this.transportDepartureTime
                ? this.transportDepartureTime.slice(0, -3)
                : null,
              accommodationArrivalTime: this.accommodationArrivalTime
                ? this.accommodationArrivalTime.slice(0, -3)
                : null,
              accommodationDepartureTime: this.accommodationDepartureTime
                ? this.accommodationDepartureTime.slice(0, -3)
                : null
            })
            .then(() => {
              this.$store
                .dispatch("booking/updateStats", {
                  uuid: this.stats.uuid,
                  arrivalOnlineCheckinCompleted: true,
                  arrivalOnlineCheckinCompletedAt: formatDateObjectToDatabaseDateTime(
                    new Date()
                  )
                })
                .finally(() => {
                  this.loading = false;
                  if (this.onlineCheckinCompleted)
                    scrollToId("checkin-status-card", 100);
                  else scrollToId("schedules-transport-card", 100);
                });
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="scss">
#schedules-form {
  .form-control.is-valid {
    border-color: #d8d6de;
    background: none;
  }
  .form-control.is-invalid {
    background: none;
  }
  .b-form-btn-label-control.form-control.is-valid > .btn {
    color: inherit;
  }
}
</style>
