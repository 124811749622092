<template>
  <div>
    <b-card
      :img-src="pictureUrl"
      img-height="280px"
      class="blink"
    >
      <b-row>
        <b-col
          cols="8"
          md="4"
        >
          <div class="placeholder placeholder-h1" />
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col
          cols="4"
          md="2"
        >
          <div class="placeholder placeholder-h2" />
        </b-col>
      </b-row>

      <b-row class="mb-3">
        <b-col
          cols="12"
          sm="4"
          class="mb-2"
        >
          <div class="font-weight-light">
            {{ $t('Booking date') }}
          </div>
          <b-row>
            <b-col cols="6">
              <div class="placeholder placeholder-h3" />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          sm="4"
          class="mb-2"
        >
          <div class="font-weight-light">
            {{ $t('Booking nº') }}
          </div>
          <b-row>
            <b-col cols="4">
              <div class="placeholder placeholder-h3" />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          sm="4"
          class="mb-2"
        >
          <div class="font-weight-light">
            {{ $t('Agency') }}
          </div>
          <b-row>
            <b-col cols="4">
              <div class="placeholder placeholder-h3" />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          sm="4"
          class="mb-2"
        >
          <div class="font-weight-light">
            {{ $t('Reference') }}
          </div>
          <b-row>
            <b-col cols="4">
              <div class="placeholder placeholder-h3" />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          sm="4"
          class="mb-2"
        >
          <div class="font-weight-light">
            {{ $t('Check-in') }}
          </div>
          <b-row>
            <b-col cols="6">
              <div class="placeholder placeholder-h3" />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          sm="4"
          class="mb-2"
        >
          <div class="font-weight-light">
            {{ $t('Check out') }}
          </div>
          <b-row>
            <b-col cols="6">
              <div class="placeholder placeholder-h3" />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          sm="4"
          class="mb-2"
        >
          <div class="font-weight-light">
            {{ $t('Stay') }}
          </div>
          <b-row>
            <b-col cols="3">
              <div class="placeholder placeholder-h3" />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          sm="4"
          class="mb-2"
        >
          <div class="font-weight-light">
            {{ $t('Guests') }}
          </div>
          <b-row>
            <b-col cols="7">
              <div class="placeholder placeholder-h3" />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="12"
          sm="4"
          class="mb-2"
        >
          <div class="font-weight-light">
            {{ $t('Booking email') }}
          </div>
          <b-row>
            <b-col cols="10">
              <div class="placeholder placeholder-h3" />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol } from 'bootstrap-vue'
import { ACCOMMODATION_PLACEHOLDER_XL } from '@/utils/consts'

export default {
  components: {
    BCard,
    BRow,
    BCol,
  },
  computed: {
    pictureUrl() {
      return ACCOMMODATION_PLACEHOLDER_XL
    },
  },
}
</script>
