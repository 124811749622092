<template>
  <div
    class="p-4 rounded status-hero bg-lighten-5 d-flex flex-sm-column w-100 align-items-center"
    :class="[bgClass]"
  >
    <div
      class="mr-4 mr-sm-0 p-3 p-sm-4 mb-0 mb-sm-4 rounded-circle status-icon"
      :class="[bgClass]"
    >
      <feather-icon :icon="iconName" class="text-light" />
    </div>
    <div>
      <div class="title text-dark font-weight-bold text-sm-center">
        {{ title }}
      </div>
      <p class="subtitle text-sm-center mb-0">
        {{ text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    variant: {
      type: String,
      required: true,
      validator(value) {
        return ["success", "warning", "danger"].indexOf(value) !== -1;
      }
    },
    title: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    }
  },
  computed: {
    bgClass() {
      return `bg-${this.variant}`;
    },
    iconName() {
      switch (this.variant) {
        case "success":
          return "CheckCircleIcon";
        case "danger":
          return "XCircleIcon";
        case "warning":
          return "AlertCircleIcon";
        default:
          return null;
      }
    }
  }
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/include";
.status-hero {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  .status-icon {
    svg {
      width: 24px;
      height: 24px;
    }
  }
  @include media-breakpoint-up(sm) {
    .status-icon {
      svg {
        width: 48px;
        height: 48px;
      }
    }
  }
}
.title {
  font-size: $font-size-base;
}
.subtitle {
  font-size: $small-font-size;
}
@include media-breakpoint-up(sm) {
  .title {
    font-size: $h3-font-size;
  }
  .subtitle {
    font-size: $font-size-base;
  }
}
</style>
