<template>
  <div id="third-party-services-card">
    <template v-if="optionalAccommodationThirdPartyServices.length">
      <h3 class="mb-4">
        {{ $t("Available services") }}
      </h3>
      <b-row class="d-none d-sm-flex">
        <b-col>
          <b-card-group deck>
            <available-service-card
              v-for="(accommodationService,
              index) in optionalAccommodationThirdPartyServices"
              :key="index"
              :accommodation-service="accommodationService"
              class="mb-4"
            />
          </b-card-group>
        </b-col>
      </b-row>
      <b-row class="d-sm-none">
        <b-col class="swiper-full-width">
          <swiper :options="swiperOptions">
            <swiper-slide
              v-for="(accommodationService,
              index) in optionalAccommodationThirdPartyServices"
              :key="index"
            >
              <available-service-card
                :accommodation-service="accommodationService"
                class="mb-4 h-100"
              />
            </swiper-slide>
          </swiper>
        </b-col>
      </b-row>
    </template>
    <template v-if="hiredThirdPartyServices.length">
      <h3
        class="mb-4"
        :class="{ 'mt-6': optionalAccommodationThirdPartyServices.length }"
      >
        {{ $t("Hired services") }}
      </h3>
      <b-row>
        <b-col
          v-for="(bookingService, index) in hiredThirdPartyServices"
          :key="index"
          cols="12"
          md="6"
          lg="4"
        >
          <hired-service-card
            :booking-service="bookingService"
            class="mb-4"
            @selected="onBookingServiceSelected"
          />
        </b-col>
      </b-row>
    </template>
    <b-sidebar
      id="service-sidebar"
      bg-variant="white"
      right
      backdrop
      shadow
      :width="sidebarWidth"
    >
      <service-sidebar :booking-service="selectedBookingService" />
    </b-sidebar>
  </div>
</template>

<script>
import { BCardGroup, BSidebar, BRow, BCol } from "bootstrap-vue";
import HiredServiceCard from "@/views/foravila/bookings/booking/components/services/HiredServiceCard.vue";
import AvailableServiceCard from "@/views/foravila/bookings/booking/components/services/AvailableServiceCard.vue";
import ServiceSidebar from "@/views/foravila/bookings/booking/components/services/ServiceSidebar.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

export default {
  components: {
    HiredServiceCard,
    AvailableServiceCard,
    BCardGroup,
    ServiceSidebar,
    BSidebar,
    BRow,
    BCol,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      selectedBookingService: null,
      swiperOptions: {
        slidesPerView: "auto",
        centeredSlides: false,
        spaceBetween: 16,
        freeMode: false,
        grabCursor: true,
        loop: false,
        slidesOffsetAfter: 16,
        slidesOffsetBefore: 16
      },
      allowedServices: ["AIRPORT_TRANSFER", "CLEANING", "CAR_RENTAL"]
    };
  },
  computed: {
    isMobile() {
      return this.$store.getters["app/isMobile"];
    },
    sidebarWidth() {
      return this.isMobile ? "90%" : "35rem";
    },
    hiredThirdPartyServices() {
      const services = this.$store.getters["booking/hiredThirdPartyServices"];

      // TODO: update the following array with the allowed services
      return services.filter(bookingService =>
        this.allowedServices.includes(bookingService.service?.code)
      );
    },
    optionalAccommodationThirdPartyServices() {
      const services = this.$store.getters[
        "booking/optionalAccommodationThirdPartyServices"
      ];

      // TODO: update the following array with the allowed services
      return services.filter(accommodationService =>
        this.allowedServices.includes(accommodationService.service?.code)
      );
    }
  },
  methods: {
    onBookingServiceSelected(bookingService) {
      this.selectedBookingService = bookingService;
    }
  }
};
</script>
<style lang="scss">
#third-party-services-card {
  .swiper-full-width {
    padding-left: 0;
    padding-right: 0;
  }
  .swiper-slide {
    width: 250px !important;
    height: auto !important;
    position: relative;
  }
}
</style>
