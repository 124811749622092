<template>
  <b-overlay :show="loading" variant="white" :opacity="0.9">
    <validation-observer ref="clientFormRules" #default="{invalid}">
      <h4>{{ $t("Booking holder") }}</h4>
      <b-form id="client-form">
        <h5 class="my-4">
          {{ $t("Personal information") }}
        </h5>
        <b-row>
          <b-col cols="12" sm="6" md="5" xl="3" class="mb-4">
            <label class="font-weight-bold">
              {{ $t("First name") }}
            </label>
            <validation-provider
              #default="{ errors }"
              :name="$t('First name')"
              rules="required"
            >
              <b-form-input
                v-model="firstName"
                class="w-full mb-2"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12" sm="6" md="7" xl="3" class="mb-4">
            <label class="font-weight-bold">
              {{ $t("Last name") }}
            </label>
            <b-form-input v-model="lastName" class="w-full mb-2" />
          </b-col>

          <b-col cols="12" md="5" xl="6" class="mb-4">
            <label class="font-weight-bold">
              {{ $t("Phone number") }}
            </label>

            <validation-provider
              #default="{ errors }"
              :name="$t('Phone number')"
              rules="required"
            >
              <vue-phone-number-input
                v-model="inputPhone"
                no-flags
                clearable
                :default-country-code="phoneCountry"
                show-code-on-list
                :translations="phoneNumberInputTranslations"
                color="#d8d6de"
                class="phone-number-input mb-2"
                error-color="#ea5455"
                valid-color="#d8d6de"
                :error="!validPhone"
                @update="onPhoneUpdated"
              >
                <template #arrow>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15px"
                      height="15px"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-chevron-down open-indicator vs__open-indicator"
                      role="presentation"
                    >
                      <polyline points="6 9 12 15 18 9" />
                    </svg>
                  </div>
                </template>
              </vue-phone-number-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="7" xl="3" class="mb-4">
            <label class="font-weight-bold">
              {{ $t("Email") }}
            </label>
            <validation-provider
              #default="{ errors }"
              :name="$t('Email')"
              rules="required|email"
            >
              <b-form-input
                v-model="clientEmail"
                type="email"
                class="w-full mb-2"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="5" xl="3" class="mb-4">
            <label class="font-weight-bold">
              {{ $t("Preferred language") }}
            </label>
            <v-select
              v-model="language"
              :options="languageOptions"
              :reduce="option => option.value"
              class="w-full mb-2"
            />
          </b-col>
        </b-row>

        <h5 class="my-4">
          {{ $t("Address") }}
        </h5>
        <b-row>
          <b-col cols="12" sm="6" md="4" xl="3" class="mb-4">
            <label class="font-weight-bold">
              {{ $t("Country") }}
            </label>
            <validation-provider
              #default="{ errors }"
              :name="$t('Country')"
              rules="required"
            >
              <v-select
                v-model="country"
                :options="countryOptions"
                :reduce="option => option.value"
                class="w-full mb-2"
                :class="{ 'is-invalid': errors.length > 0 }"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12" sm="6" md="4" xl="3" class="mb-4">
            <label class="font-weight-bold">
              {{ $t("Region") }}
            </label>
            <validation-provider
              #default="{ errors }"
              :name="$t('Region')"
              rules="required"
            >
              <b-form-input
                v-model="region"
                class="w-full mb-2"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12" sm="6" md="4" xl="3" class="mb-4">
            <label class="font-weight-bold">
              {{ $t("City") }}
            </label>
            <validation-provider
              #default="{ errors }"
              :name="$t('City')"
              rules="required"
            >
              <b-form-input
                v-model="city"
                class="w-full mb-2"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12" sm="6" md="4" xl="3" class="mb-4">
            <label class="font-weight-bold">
              {{ $t("Postal code") }}
            </label>
            <validation-provider
              #default="{ errors }"
              :name="$t('Postal code')"
              rules="required"
            >
              <b-form-input
                v-model="zip"
                class="w-full mb-2"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
          <b-col cols="12" md="8" xl="12" class="mb-4">
            <label class="font-weight-bold">
              {{ $t("Address") }}
            </label>
            <validation-provider
              #default="{ errors }"
              :name="$t('Address')"
              rules="required"
            >
              <b-form-input
                v-model="address"
                class="w-full mb-2"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>

        <h5 class="my-4">
          {{ $t("Identification") }}
        </h5>
        <b-row>
          <b-col class="mb-4" cols="12" md="6">
            <label class="font-weight-bold mb-2" for="client-id-type">
              {{ $t("ID type") }}
            </label>
            <validation-provider
              #default="{ errors }"
              :name="$t('ID type')"
              rules="required"
            >
              <b-form-group>
                <b-form-radio-group
                  id="client-id-type"
                  v-model="idType"
                  :options="idTypeOptions"
                  text-field="label"
                  class="fr-radio-button w-100"
                  buttons
                  button-variant="outline-secondary"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4" xl="3" class="mb-4">
            <b-row>
              <b-col cols="12">
                <label class="font-weight-bold">
                  {{ $t("ID number") }}
                </label>
                <validation-provider
                  #default="{ errors }"
                  :name="$t('ID number')"
                  rules="required"
                >
                  <b-form-input
                    v-model="idNumber"
                    class="w-full mb-2"
                    :state="errors.length > 0 ? false : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="d-flex justify-content-end">
            <b-button
              variant="primary"
              type="submit"
              :disabled="invalid"
              @click.prevent="save"
            >
              {{ $t("Save") }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormRadioGroup,
  BForm,
  BFormGroup
} from "bootstrap-vue";
import vSelect from "vue-select";
import { countryOptions, idTypeOptions } from "@select-options";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { getCountryCodeFromDialCode, scrollToId } from "@methods";
import { formatDateObjectToDatabaseDateTime } from "@formatters";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required, email } from "@foravila-validations";

export default {
  components: {
    BOverlay,
    BFormGroup,
    BButton,
    BFormInput,
    BRow,
    BCol,
    vSelect,
    VuePhoneNumberInput,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
    BForm
  },
  data() {
    return {
      loading: false,
      firstName: null,
      lastName: null,
      nationality: null,
      language: null,
      inputPhone: null,
      phone: null,
      phoneCountry: null,
      validPhone: null,
      clientEmail: null,
      country: null,
      region: null,
      city: null,
      zip: null,
      address: null,
      idType: "PASSPORT",
      idNumber: null,
      // Validation rules
      required,
      email
    };
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale.substring(0, 2).toLowerCase();
    },
    languageOptions() {
      const options = [];
      const langEn = { label: this.$t("Language-en"), value: "en" };
      const langDe = { label: this.$t("Language-de"), value: "de" };
      const langEs = { label: this.$t("Language-es"), value: "es" };
      const langCa = { label: this.$t("Language-ca"), value: "ca" };

      switch (this.currentLanguage) {
        case "de":
          options.push(langDe, langEn, langCa, langEs);
          break;
        case "en":
          options.push(langEn, langEs, langCa, langDe);
          break;
        case "es":
          options.push(langEs, langCa, langEn, langDe);
          break;
        case "ca":
          options.push(langCa, langEs, langEn, langDe);
          break;
        default:
          options.push(langEn, langDe, langCa, langEs);
          break;
      }

      return options;
    },
    stats() {
      return this.$store.getters["booking/stats"];
    },
    client() {
      return this.$store.getters["client/client"];
    },
    clientLocation() {
      return this.$store.getters["client/location"];
    },
    idTypeOptions() {
      return idTypeOptions(this.$i18n.locale);
    },
    countryOptions() {
      return countryOptions(this.$i18n.locale);
    },
    phoneNumberInputTranslations() {
      return {
        countrySelectorLabel: this.$t(
          "pages.booking.phoneNumberInput.countrySelectorLabel"
        ),
        countrySelectorError: "",
        phoneNumberLabel: "",
        example: this.$t("pages.booking.phoneNumberInput.example")
      };
    },
    clientOnlineCheckinCompleted() {
      return this.$store.getters["booking/clientOnlineCheckinCompleted"];
    },
    onlineCheckinCompleted() {
      return this.$store.getters["booking/onlineCheckinCompleted"];
    },
    isStacked() {
      const currentBreakpoint = this.$store.getters["app/currentBreakPoint"];
      return currentBreakpoint === "xs" || currentBreakpoint === "sm" || false;
    }
  },
  watch: {
    nationality(nationality) {
      if (!this.inputPhone) this.phoneCountry = nationality;
    },
    currentLanguage() {
      this.switchValidationLocale();
    }
  },
  created() {
    if (this.client) this.initFields();
  },
  mounted() {
    this.switchValidationLocale();
  },
  methods: {
    initFields() {
      if (!this.client) return;
      this.firstName = this.client.firstName;
      this.lastName = this.client.lastName;
      this.nationality = this.client.nationality;
      this.phoneCountry = this.client.phoneCountry;
      this.idType = this.client.idType;
      this.idNumber = this.client.idNumber;
      this.initLanguageField();
      this.initPhoneFields();
      this.initAddressFields();
    },
    initPhoneFields() {
      if (!this.client) return;
      const phoneFields = this.client.phone
        ? this.client.phone.replaceAll("-", "").split(" ")
        : [];
      let phoneNumber = null;
      if (phoneFields.length > 1) {
        const dialCode = phoneFields[0] || null;
        this.phoneCountry = getCountryCodeFromDialCode(dialCode);
        phoneNumber = phoneFields.slice(1).join("") || null;
      } else {
        phoneNumber = phoneFields[0] || null;
      }
      this.inputPhone = phoneNumber;
      this.phone = this.client.phone;
    },
    initAddressFields() {
      if (!this.clientLocation) return;

      this.country = this.clientLocation.country;
      this.region = this.clientLocation.region;
      this.city = this.clientLocation.city;
      this.zip = this.clientLocation.zip;

      const address = [];
      if (this.clientLocation.addressName)
        address.push(this.clientLocation.addressName);
      if (this.clientLocation.addressNumber)
        address.push(this.clientLocation.addressNumber);
      this.address = address.length ? address.join(" ") : null;
    },
    initLanguageField() {
      if (!this.client) return;
      const availableLanguageCodes = this.languageOptions.map(lo => lo.value);
      if (availableLanguageCodes.includes(this.client.language)) {
        this.language = this.client.language;
      }
    },
    onPhoneUpdated(phoneData) {
      this.validPhone = phoneData.isValid;
      this.phone = phoneData.formatInternational || null;
    },
    switchValidationLocale() {
      localize(this.currentLanguage);
      this.$refs.clientFormRules.validate();
    },
    save() {
      this.$refs.clientFormRules.validate().then(success => {
        if (success) {
          this.loading = true;

          this.$store
            .dispatch("client/updateClient", {
              uuid: this.client.uuid,
              firstName: this.firstName || null,
              lastName: this.lastName || null,
              nationality: this.nationality || null,
              language: this.language || null,
              phone: this.phone || null,
              email: this.clientEmail || null,
              idType: this.idType || null,
              idNumber: this.idNumber || null,
              location: {
                uuid: this.clientLocation.uuid,
                country: this.country || null,
                region: this.region || null,
                city: this.city || null,
                zip: this.zip || null,
                addressType: null,
                addressName: this.address || null,
                addressNumber: null,
                addressBlock: null,
                addressPortal: null,
                addressStairs: null,
                addressFloor: null,
                addressDoor: null,
                addressPolygon: null,
                addressPlot: null,
                area: null
              }
            })
            .then(() => {
              this.$store
                .dispatch("booking/updateStats", {
                  uuid: this.stats.uuid,
                  clientOnlineCheckinCompleted: true,
                  clientOnlineCheckinCompletedAt: formatDateObjectToDatabaseDateTime(
                    new Date()
                  )
                })
                .finally(() => {
                  this.loading = false;
                  if (this.onlineCheckinCompleted)
                    scrollToId("checkin-status-card", 100);
                  else scrollToId("client-card", 100);
                });
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "~@core/scss/base/bootstrap-extended/include";
#client-form {
  .v-select-invalid > .vs__dropdown-toggle {
    border-color: #ea5455;
  }
  .phone-number-inputs {
    .select-country-container {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100px;
      flex: 0 0 100px;
      width: 100px;
      min-width: 100px;
      max-width: 100px;
    }
    .country-selector,
    .input-tel {
      height: 38px !important;
      min-height: 38px !important;
      font-family: inherit !important;
      input {
        color: #6e6b7b;
        border-color: #d8d6de;
        height: 38px !important;
        min-height: 38px !important;
        &:hover {
          border-color: #d8d6de !important;
        }
        &:focus {
          border-color: #ffcd00 !important;
          box-shadow: 0 3px 10px 0 rgb(34 41 47 / 10%) !important;
        }
      }
    }
    .country-selector__label,
    .input-tel__label {
      font-size: 8px;
    }
    .input-tel__clear:hover {
      color: #6e6b7b;
    }
    .input-tel__clear__effect {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 24px;
      height: 24px;
      background-color: transparent !important;
      border-radius: 24px;
      -webkit-transform: none;
      transform: none;
      -webkit-transition: none !important;
      transition: none !important;
    }
    .country-selector__toggle:hover > .country-selector input {
      border-color: #d8d6de !important;
    }
    .country-selector__list__item__calling-code {
      color: #6e6b7b;
    }
    .country-selector__list {
      margin: 10px 0 0 0;
      box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
    }
    .dots-text {
      color: #6e6b7b;
    }
  }
}
</style>
