<template>
  <b-overlay :show="loading" variant="white" :opacity="0.9">
    <h4 class="mb-4">
      {{ $t("pages.booking.review.yourReview") }}
    </h4>

    <validation-observer ref="reviewFormRules" #default="{invalid}">
      <b-row>
        <b-col cols="12" lg="6">
          <h6>
            {{ $t("Facilities") }}
          </h6>
          <p class="mb-0">
            {{ $t("pages.booking.review.facilitiesDefinition") }}
          </p>
          <validation-provider
            #default="{ errors }"
            :name="$t('Facilities')"
            vid="rateFacilities"
            rules="required"
          >
            <div class="d-flex align-items-center mb-2">
              <b-form-rating
                v-model="rateFacilities"
                variant="primary"
                inline
                no-border
                class="p-0"
                size="lg"
              />
              <small class="ml-3 mt-1 text-danger">{{ errors[0] }}</small>
            </div>
          </validation-provider>
        </b-col>
        <b-col cols="12" lg="6" class="mt-3 mt-lg-0">
          <h6>
            {{ $t("Cleaning") }}
          </h6>
          <p class="mb-0">
            {{ $t("pages.booking.review.cleaningDefinition") }}
          </p>
          <validation-provider
            #default="{ errors }"
            :name="$t('Cleaning')"
            vid="rateCleaning"
            rules="required"
          >
            <div class="d-flex align-items-center mb-2">
              <b-form-rating
                v-model="rateCleaning"
                variant="primary"
                inline
                no-border
                class="p-0"
                size="lg"
              />
              <small class="ml-3 mt-1 text-danger">{{ errors[0] }}</small>
            </div>
          </validation-provider>
        </b-col>
        <b-col cols="12" lg="6" class="mt-3 mt-lg-0">
          <h6>
            {{ $t("Arrival") }}
          </h6>
          <p class="mb-0">
            {{ $t("pages.booking.review.checkinDefinition") }}
          </p>
          <validation-provider
            #default="{ errors }"
            :name="$t('Arrival')"
            vid="rateCheckin"
            rules="required"
          >
            <div class="d-flex align-items-center mb-2">
              <b-form-rating
                v-model="rateCheckin"
                variant="primary"
                inline
                no-border
                class="p-0"
                size="lg"
              />
              <small class="ml-3 mt-1 text-danger">{{ errors[0] }}</small>
            </div>
          </validation-provider>
        </b-col>
        <b-col cols="12" lg="6" class="mt-3 mt-lg-0">
          <h6>
            {{ $t("Location") }}
          </h6>
          <p class="mb-0">
            {{ $t("pages.booking.review.locationDefinition") }}
          </p>
          <validation-provider
            #default="{ errors }"
            :name="$t('Location')"
            vid="rateLocation"
            rules="required"
          >
            <div class="d-flex align-items-center mb-2">
              <b-form-rating
                v-model="rateLocation"
                variant="primary"
                inline
                no-border
                class="p-0"
                size="lg"
              />
              <small class="ml-3 mt-1 text-danger">{{ errors[0] }}</small>
            </div>
          </validation-provider>
        </b-col>
        <b-col cols="12" lg="6" class="mt-3 mt-lg-0">
          <h6>
            {{ $t("Communication") }}
          </h6>
          <p class="mb-0">
            {{ $t("pages.booking.review.communicationDefinition") }}
          </p>
          <validation-provider
            #default="{ errors }"
            :name="$t('Communication')"
            vid="rateCommunication"
            rules="required"
          >
            <div class="d-flex align-items-center mb-2">
              <b-form-rating
                v-model="rateCommunication"
                variant="primary"
                inline
                no-border
                class="p-0"
                size="lg"
              />
              <small class="ml-3 mt-1 text-danger">{{ errors[0] }}</small>
            </div>
          </validation-provider>
        </b-col>
        <b-col cols="12" lg="6" class="mt-3 mt-lg-0">
          <h6>
            {{ $t("Accuracy") }}
          </h6>
          <p class="mb-0">
            {{ $t("pages.booking.review.accuracyDefinition") }}
          </p>
          <validation-provider
            #default="{ errors }"
            :name="$t('Accuracy')"
            vid="rateAccuracy"
            rules="required"
          >
            <div class="d-flex align-items-center mb-2">
              <b-form-rating
                v-model="rateAccuracy"
                variant="primary"
                inline
                no-border
                class="p-0"
                size="lg"
              />
              <small class="ml-3 mt-1 text-danger">{{ errors[0] }}</small>
            </div>
          </validation-provider>
        </b-col>
        <b-col cols="12" class="mt-4">
          <h6>
            {{ $t("pages.booking.review.privateReview") }}
          </h6>
          <p>
            {{ $t("pages.booking.review.privateReviewDefinition") }}
          </p>
          <b-form-textarea v-model="privateComment" rows="3" />
        </b-col>
        <b-col cols="12" class="mt-4">
          <h6>
            {{ $t("pages.booking.review.publicReview") }}
          </h6>
          <p>
            {{ $t("pages.booking.review.publicReviewDefinition") }}
          </p>
          <b-form-textarea v-model="comment" rows="3" />
        </b-col>
        <b-col cols="12" md="6" lg="4" class="mt-3">
          <p>
            {{ $t("pages.booking.review.writtenLanguage") }}
          </p>
          <v-select
            v-model="language"
            :options="languageOptions"
            :reduce="option => option.value"
            class="mb-2 w-auto"
          />
        </b-col>
      </b-row>
      <b-row class="mt-4">
        <b-col class="d-flex justify-content-end">
          <b-button variant="primary" :disabled="invalid" @click="validateForm">
            {{ $t("pages.booking.review.postReview") }}
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BFormRating,
  BFormTextarea,
  BButton
} from "bootstrap-vue";
import { formatDateObjectToDatabaseDateTime } from "@/utils/formatters";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required } from "@foravila-validations";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BFormRating,
    BFormTextarea,
    BButton,
    vSelect,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      loading: false,
      rateFacilities: null,
      rateCleaning: null,
      rateLocation: null,
      rateCheckin: null,
      rateCommunication: null,
      rateAccuracy: null,
      privateComment: null,
      comment: null,
      language: null,
      required
    };
  },
  computed: {
    currentLocale() {
      return this.$i18n.locale.substring(0, 2).toLowerCase();
    },
    client() {
      return this.$store.getters["client/client"];
    },
    review() {
      return this.$store.getters["booking/review"];
    },
    posted() {
      return this.review?.posted || false;
    },
    languageOptions() {
      const options = [];

      const langEn = { label: this.$t("Language-en"), value: "en" };
      const langEs = { label: this.$t("Language-es"), value: "es" };
      const langCa = { label: this.$t("Language-ca"), value: "ca" };
      const langDe = { label: this.$t("Language-de"), value: "de" };

      switch (this.currentLocale) {
        case "de":
          options.push(langDe, langEn, langCa, langEs);
          break;
        case "en":
          options.push(langEn, langEs, langCa, langDe);
          break;
        case "es":
          options.push(langEs, langCa, langEn, langDe);
          break;
        case "ca":
          options.push(langCa, langEs, langEn, langDe);
          break;
        default:
          options.push(langEn, langEs, langCa, langDe);
          break;
      }
      return options;
    },
    emptyForm() {
      return (
        !this.rateFacilities &&
        !this.rateCleaning &&
        !this.rateLocation &&
        !this.rateCheckin &&
        !this.rateCommunication &&
        !this.rateAccuracy
      );
    }
  },
  watch: {
    currentLocale() {
      this.initReviewLanguage(this.currentLocale);
      // ! Working but improvement needed
      // TODO: we use nextTick to allow vee-validate to update the validation messages
      this.$nextTick(() => {
        this.switchValidationLocale();
      });
    }
  },
  created() {
    this.initReviewLanguage(this.currentLocale);
  },
  mounted() {
    this.switchValidationLocale();
  },
  methods: {
    switchValidationLocale() {
      localize(this.currentLocale);
      if (!this.emptyForm) this.$refs.reviewFormRules.validate();
    },
    initReviewLanguage(locale) {
      this.language = locale;
    },
    validateForm() {
      this.$refs.reviewFormRules.validate().then(success => {
        if (success) {
          this.loading = true;

          this.$store
            .dispatch("booking/updateReview", {
              uuid: this.review.uuid,
              rateCleaning: this.rateCleaning || null,
              rateAccuracy: this.rateAccuracy || null,
              rateLocation: this.rateLocation || null,
              rateCheckin: this.rateCheckin || null,
              rateCommunication: this.rateCommunication || null,
              rateFacilities: this.rateFacilities || null,
              privateComment: this.privateComment || null,
              comment: this.comment || null,
              author: this.client.fullName || null,
              postedAt: formatDateObjectToDatabaseDateTime(new Date()),
              language:
                this.privateComment || this.comment ? this.language : null
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    }
  }
};
</script>
