<template>
  <b-card id="schedules-transport-card">
    <template #header>
      <status-hero
        :variant="heroVariant"
        :title="heroTitle"
        :text="heroText"
      />
    </template>

    <div class="mt-4">

      <schedules-transport-details v-if="arrivalOnlineCheckinCompleted" />
      <schedules-transport-form v-else />
    </div>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import StatusHero from '@/views/foravila/bookings/booking/components/checkin/StatusHero.vue'
import SchedulesTransportForm from '@/views/foravila/bookings/booking/components/checkin/SchedulesTransportForm.vue'
import SchedulesTransportDetails from '@/views/foravila/bookings/booking/components/checkin/SchedulesTransportDetails.vue'

export default {
  components: {
    BCard,
    StatusHero,
    SchedulesTransportForm,
    SchedulesTransportDetails,
  },
  computed: {
    clientOnlineCheckinCompleted() {
      return this.$store.getters['booking/clientOnlineCheckinCompleted']
    },
    guestsOnlineCheckinCompleted() {
      return this.$store.getters['booking/guestsOnlineCheckinCompleted']
    },
    arrivalOnlineCheckinCompleted() {
      return this.$store.getters['booking/arrivalOnlineCheckinCompleted']
    },
    heroVariant() {
      return this.arrivalOnlineCheckinCompleted ? 'success' : 'warning'
    },
    heroTitle() {
      return this.arrivalOnlineCheckinCompleted
        ? this.$t('pages.booking.checkin.awesome')
        : this.$t('pages.booking.checkin.alreadyHaveTickets')
    },
    heroText() {
      if (!this.arrivalOnlineCheckinCompleted) {
        return this.$t('pages.booking.checkin.completeArrivalInformation')
      }

      if (!this.clientOnlineCheckinCompleted) {
        return this.$t('pages.booking.checkin.arrivalInformationCompletedNowClient')
      }

      if (!this.guestsOnlineCheckinCompleted) {
        return this.$t('pages.booking.checkin.arrivalInformationCompletedNowGuests')
      }

      return this.$t('pages.booking.checkin.arrivalInformationCompleted')
    },
  },
}
</script>

<style lang="scss">
#schedules-transport-card {
  .card-header {
    padding: 0 !important;
  }
}
</style>
